import React, { useState, useEffect, useCallback } from 'react';
import './Analytics.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  AreaChart,
  Area,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSpinner, faMoneyBillWave, faChartLine, faChartBar, faChartArea, faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Added icons and checkmark
import { useAuth } from "@clerk/clerk-react"; // Import useAuth from Clerk

const API_BASE_URL = 'https://api.neocu.com/api/advisor';
const API_FEED_URL = 'https://api.neocu.com/api/feed';
const API_ORG_URL = 'https://api.neocu.com/api/organization'; // Organization API URL

const Analytics = () => {
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [selectedAction, setSelectedAction] = useState('impression');
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [tempDateRange, setTempDateRange] = useState({ from: null, to: null });
  const [days, setDays] = useState(0);
  const [chartType, setChartType] = useState('line');
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [isCompareMode, setIsCompareMode] = useState(false);
  const [comparedActions, setComparedActions] = useState([]);
  const [activePresetRange, setActivePresetRange] = useState(null);
  const [skuItems, setSkuItems] = useState([]);
  const [selectedSkuId, setSelectedSkuId] = useState(null);
  const [isEarningsModalOpen, setIsEarningsModalOpen] = useState(false); // State for earnings modal
  const { userId } = useAuth(); // Use useAuth to get userId from Clerk
  const [organizationData, setOrganizationData] = useState(null); // State to store organization data
  const [monetizeStatus, setMonetizeStatus] = useState(null); // State to store monetize status
  const [applyingForEarnings, setApplyingForEarnings] = useState(false); // State for applying earnings loading

  const actions = ['impression', 'play', 'favorite', 'love', 'earn']; // Removed 'share' and 'leave', Removed 'playSeconds' from actions tab list, but keep in summary data
  const actionLabels = {
    impression: 'Impression',
    play: 'Plays',
    favorite: 'Favorites',
    love: 'Loves',
    earn: 'Earn', // Added 'earn' label
    playSeconds: 'Playtime(s)', // Changed 'Seconds' to 'Playtime' for summary card
  };
  const actionDescriptions = {
    impression: 'Total number of times content was displayed.',
    play: 'Number of times users played the content.',
    favorite: 'Number of times users marked content as favorite.',
    love: 'Number of times users expressed love for content.',
    earn: 'Total earnings generated.', // Added 'earn' description
    playSeconds: 'Total seconds of content played by users.', // Added 'playSeconds' description, still used for summary data
  };

  const chartColors = ['#ff7f00', '#a64d00', '#e69900', '#cc6600', '#f2b266', '#ffa64d'];

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  const formatChartDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const formatNumber = (number) => {
    if (number >= 1000000) {
      const millions = (number / 1000000).toFixed(1);
      return `${millions}M`;
    } else if (number >= 1000) {
      const thousands = (number / 1000).toFixed(1);
      return `${thousands}K`;
    }
    return number;
  };

  const fetchOrganizationData = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await fetch(`${API_ORG_URL}?user_id=${userId}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      if (data.organizationList && data.organizationList.length > 0) {
        const orgData = data.organizationList[0];
        setOrganizationData(orgData);
        setMonetizeStatus(orgData.monetize); // Access monetize status directly
      } else {
        setOrganizationData(null);
        setMonetizeStatus(undefined); // Default to undefined if no org data
      }
    } catch (error) {
      console.error('Could not fetch organization data:', error);
      setOrganizationData(null);
      setMonetizeStatus(undefined); // Default to undefined on error
    }
  }, [userId]);


  const fetchSkuItems = useCallback(async () => {
    try {
      let url = `${API_FEED_URL}?category=sku`;
      if (userId) {
        url += `&user_id=${userId}`;
      }
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setSkuItems([{ _id: null, title: 'All' }, ...(data.feedList || [])]);
    } catch (error) {
      console.error('Could not fetch SKU items:', error);
      setSkuItems([{ _id: null, title: 'All' }]);
    }
  }, [userId]);

  const fetchSummaryData = useCallback(async (skuId = null) => {
    setLoadingSummary(true); // Keep loading summary true for initial and refresh
    let url = `${API_BASE_URL}/count${skuId ? `?sku_id=${skuId}` : ''}`;
    if (userId) {
      url += `${skuId ? '&' : '?'}user_id=${userId}`;
    }
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      if (data.isSuccess) {
        setSummaryData(data.analytics);
        const today = new Date();
        const fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 7);
        const initialDateRange = { from: fromDate.toISOString().split('T')[0], to: today.toISOString().split('T')[0] };
        setDateRange(initialDateRange);
        setTempDateRange(initialDateRange);
        setActivePresetRange('Week');
      } else {
        console.error('API Summary Error:', data);
      }
    } catch (error) {
      console.error('Could not fetch summary data:', error);
    } finally {
      setLoadingSummary(false); // Keep loading summary false after fetch
    }
  }, [userId]);

  const fetchChartData = useCallback(async (action, fromDate, toDate, skuId = null) => {
    if (!fromDate || !toDate) return;
    setLoadingChart(true); // Set loading chart true before chart data fetch
    let url = `${API_BASE_URL}/daily?action=${action}&dateFrom=${fromDate}&dateTo=${toDate}${skuId ? `&sku_id=${skuId}` : ''}`;
    if (userId) {
      url += `${skuId || url.includes('?') ? '&' : '?'}user_id=${userId}`;
    }
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      if (data.isSuccess) {
        setChartData(data.daily || []);
      } else {
        console.error('API Chart Error:', data);
        setChartData([]);
      }
    } catch (error) {
      console.error('Could not fetch chart data:', error);
      setChartData([]);
    } finally {
      setLoadingChart(false); // Set loading chart false after chart data fetch
    }
  }, [userId]);

  const fetchCompareChartData = useCallback(async (actionsList, fromDate, toDate, skuId = null) => {
    if (!fromDate || !toDate || actionsList.length === 0) return;
    setLoadingChart(true); // Set loading chart true before compare chart data fetch
    const allChartData = {};
    try {
      await Promise.all(
        actionsList.map(async (action) => {
          let url = `${API_BASE_URL}/daily?action=${action}&dateFrom=${fromDate}&dateTo=${toDate}${skuId ? `&sku_id=${skuId}` : ''}`;
          if (userId) {
            url += `${skuId || url.includes('?') ? '&' : '?'}user_id=${userId}`;
          }
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status} for action ${action}`);
          const data = await response.json();
          allChartData[action] = data.isSuccess ? (data.daily || []) : [];
        })
      );
      setChartData(allChartData);
    } catch (error) {
      console.error('Could not fetch compare chart data:', error);
      setChartData({});
    } finally {
      setLoadingChart(false); // Set loading chart false after compare chart data fetch
    }
  }, [userId]);

  useEffect(() => {
    fetchOrganizationData(); // Fetch organization data on component mount
    fetchSkuItems();
    fetchSummaryData();
  }, [fetchOrganizationData, fetchSkuItems, fetchSummaryData]);

  useEffect(() => {
    if (dateRange.from && dateRange.to && !isCompareMode) {
      fetchChartData(selectedAction, dateRange.from, dateRange.to, selectedSkuId);
    } else if (dateRange.from && dateRange.to && isCompareMode && comparedActions.length > 0) {
      fetchCompareChartData(comparedActions, dateRange.from, dateRange.to, selectedSkuId);
    }
  }, [selectedAction, dateRange, fetchChartData, isCompareMode, comparedActions, fetchCompareChartData, selectedSkuId]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      const start = new Date(dateRange.from);
      const end = new Date(dateRange.to);
      const differenceInDays = Math.ceil((end - start) / (1000 * 3600 * 24));
      setDays(differenceInDays);
    }
  }, [dateRange]);

  const handleActionClick = (action) => {
    if (isCompareMode) {
      setComparedActions((prev) =>
        prev.includes(action) ? prev.filter((a) => a !== action) : [...prev, action]
      );
    } else {
      setSelectedAction(action);
    }
  };

  const handleDateInputChange = (event) => {
    const { name, value } = event.target;
    setTempDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const applyDateRange = () => {
    setDateRange(tempDateRange);
    setActivePresetRange(null);
  };

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  const handleRefresh = () => {
    fetchSummaryData(selectedSkuId);
    if (dateRange.from && dateRange.to && !isCompareMode) {
      fetchChartData(selectedAction, dateRange.from, dateRange.to, selectedSkuId);
    } else if (dateRange.from && dateRange.to && isCompareMode && comparedActions.length > 0) {
      fetchCompareChartData(comparedActions, dateRange.from, dateRange.to, selectedSkuId);
    }
  };

  const handlePresetDateRange = (range) => {
    const today = new Date();
    let fromDate = new Date(today);
    let toDate = today;
    switch (range) {
      case 'Week':
        fromDate.setDate(today.getDate() - 7);
        break;
      case 'Month':
        fromDate.setDate(today.getDate() - 30);
        break;
      case 'Year':
        fromDate.setDate(today.getDate() - 365);
        break;
      case '2 Days':
        fromDate.setDate(today.getDate() - 2);
        break;
      default:
        return;
    }
    const formattedFromDate = fromDate.toISOString().split('T')[0];
    const formattedToDate = toDate.toISOString().split('T')[0];
    const newDateRange = { from: formattedFromDate, to: formattedToDate };
    setDateRange(newDateRange);
    setTempDateRange(newDateRange);
    setActivePresetRange(range);
    if (!isCompareMode) {
      fetchChartData(selectedAction, formattedFromDate, formattedToDate, selectedSkuId);
    } else {
      fetchCompareChartData(comparedActions, formattedFromDate, formattedToDate, selectedSkuId);
    }
  };

  const toggleCompareMode = () => {
    setIsCompareMode((prev) => !prev);
    if (!isCompareMode) {
      setComparedActions([selectedAction]);
    } else {
      setComparedActions([]);
      setChartData([]);
    }
  };

  const handleSkuChange = (event) => {
    const skuId = event.target.value === 'All' ? null : event.target.value;
    setSelectedSkuId(skuId);
    fetchSummaryData(skuId);
    if (dateRange.from && dateRange.to && !isCompareMode) {
      fetchChartData(selectedAction, dateRange.from, dateRange.to, skuId);
    } else if (dateRange.from && dateRange.to && isCompareMode && comparedActions.length > 0) {
      fetchCompareChartData(comparedActions, dateRange.from, dateRange.to, skuId);
    }
  };

  const renderChart = () => {
    if (loadingChart) {
      return (
        <div className="chart-overlay">
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      );
    }

    if (isCompareMode) {
      if (!chartData || typeof chartData !== 'object' || Object.keys(chartData).length === 0) {
        return <div className="chart-no-data">No data to display for the selected criteria.</div>;
      }

      const allDates = new Set();
      comparedActions.forEach((action) => {
        (chartData[action] || []).forEach((item) => allDates.add(item.date));
      });
      const sortedDates = Array.from(allDates).sort();
      const consolidatedData = sortedDates.map((date) => {
        const dataPoint = { date };
        comparedActions.forEach((action) => {
          const actionData = chartData[action] || [];
          const dailyData = actionData.find((item) => item.date === date);
          dataPoint[action] = dailyData ? dailyData.count : 0;
        });
        return dataPoint;
      });

      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={consolidatedData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="date" tickFormatter={formatChartDate} stroke="#ff7f00" />
            <YAxis stroke="#ff7f00">
              <Label value="Actions" angle={-90} position="insideLeft" style={{ fill: '#ff7f00' }} />
            </YAxis>
            <Tooltip contentStyle={{ backgroundColor: '#333', border: '1px solid #ff7f00' }} />
            <Legend />
            {comparedActions.map((action, index) => (
              <Line
                key={action}
                type="monotone"
                dataKey={action}
                stroke={chartColors[index % chartColors.length]}
                name={actionLabels[action]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      );
    }

    if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
      return loadingChart ? ( // Show spinner only when chart is loading and no data
        <div className="chart-overlay">
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      ) : (
        <div className="chart-no-data">No data to display for the selected criteria.</div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        {chartType === 'line' ? (
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="date" tickFormatter={formatChartDate} stroke="#ff7f00" />
            <YAxis stroke="#ff7f00">
              <Label value={actionLabels[selectedAction]} angle={-90} position="insideLeft" style={{ fill: '#ff7f00' }} />
            </YAxis>
            <Tooltip contentStyle={{ backgroundColor: '#333', border: '1px solid #ff7f00' }} />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#ff7f00" name={actionLabels[selectedAction]} />
            {selectedAction === 'play' && chartData[0]?.totalSeconds !== undefined && (
              <Line type="monotone" dataKey="totalSeconds" stroke="#a64dff" name="Seconds" />
            )}
          </LineChart>
        ) : chartType === 'bar' ? (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="date" tickFormatter={formatChartDate} stroke="#ff7f00" />
            <YAxis stroke="#ff7f00">
              <Label value={actionLabels[selectedAction]} angle={-90} position="insideLeft" style={{ fill: '#ff7f00' }} />
            </YAxis>
            <Tooltip contentStyle={{ backgroundColor: '#333', border: '1px solid #ff7f00' }} />
            <Legend />
            <Bar dataKey="count" fill="#ff7f00" />
          </BarChart>
        ) : (
          <AreaChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="date" tickFormatter={formatChartDate} stroke="#ff7f00" />
            <YAxis stroke="#ff7f00">
              <Label value={actionLabels[selectedAction]} angle={-90} position="insideLeft" style={{ fill: '#ff7f00' }} />
            </YAxis>
            <Tooltip contentStyle={{ backgroundColor: '#333', border: '1px solid #ff7f00' }} />
            <Legend />
            <Area type="monotone" dataKey="count" stroke="#ff7f00" fill="#ff7f00" fillOpacity={0.3} />
          </AreaChart>
        )}
      </ResponsiveContainer>
    );
  };

  const toggleEarningsModal = () => {
    setIsEarningsModalOpen(!isEarningsModalOpen);
  };

  const handleApplyForEarnings = async (paymentMethod) => {
    if (!organizationData?._id) {
      console.error("Organization ID is missing.");
      return;
    }
    setApplyingForEarnings(true);
    try {
      const response = await fetch(`${API_ORG_URL}/${organizationData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ monetize: 'progress' }), // Sending monetize status directly
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.isSuccess) {
        setMonetizeStatus('progress');
        fetchOrganizationData(); // Refresh organization data to reflect status change
      } else {
        console.error("Failed to update monetization status:", data);
        // Optionally handle error feedback to user
      }
    } catch (error) {
      console.error("Could not update monetization status:", error);
      // Optionally handle error feedback to user
    } finally {
      setApplyingForEarnings(false);
    }
  };


  const renderEarningsModal = () => {
    if (!isEarningsModalOpen) return null;

    let modalContent;
    let modalDescription = "";
    let showSpinner = false;
    let showPortalButton = false;
    let portalUrl = "";
    let earningsButtonText = <FontAwesomeIcon icon={faMoneyBillWave} />;


    if (monetizeStatus === 'pending' || monetizeStatus === undefined || monetizeStatus === null) {
      modalDescription = "Apply To Earn";
      modalContent = (
        <>
          <p>Welcome to your earnings portal where you can connect your payment accounts and view payouts.</p>
          <div className="earnings-options">
            <button className="earnings-option-button earnings-option-button-bank" onClick={() => handleApplyForEarnings('bank')}>Bank</button>
            <button className="earnings-option-button earnings-option-button-paypal" onClick={() => handleApplyForEarnings('paypal')}>PayPal</button>
          </div>
          {applyingForEarnings && <div className="earnings-applying-spinner"><FontAwesomeIcon icon={faSpinner} spin /> Applying...</div>}
        </>
      );
      earningsButtonText = "Apply To Earn";

    } else if (monetizeStatus === 'progress') {
      modalDescription = "Verifying Earn Account";
      modalContent = (
        <>
          <div className="earnings-applying-spinner"><FontAwesomeIcon icon={faSpinner} spin size="2x" /></div>
          <p>Your application is in progress, look out for emails on next steps.</p>
        </>
      );
      showSpinner = true;
      earningsButtonText = "Verifying Earn Account";

    } else if (monetizeStatus === 'complete') {
      modalDescription = "Neocu Earn";
      modalContent = (
        <>
          <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{ color: 'green', marginBottom: '10px' }} />
          <p>Your Neocu Earn account is active.</p>
          {organizationData?.portal && (
            <button className="earnings-portal-button" onClick={() => window.open(organizationData.portal, '_blank', 'noopener,noreferrer')}>
              Portal
            </button>
          )}
        </>
      );
      showPortalButton = true;
      portalUrl = organizationData?.portal;
      earningsButtonText = <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} />Neocu Earn</>;
    } else {
      modalDescription = "Earnings"; // Fallback
      modalContent = <p>Error loading earnings information.</p>;
      earningsButtonText = <FontAwesomeIcon icon={faMoneyBillWave} />; // Fallback icon
    }


    return (
      <div className="earnings-modal-overlay">
        <div className="earnings-modal">
          <div className="earnings-modal-header">
            <h2>{modalDescription}</h2>
            <button className="earnings-modal-close-button" onClick={toggleEarningsModal}>X</button>
          </div>
          <div className="earnings-modal-body">
            {modalContent}
          </div>
        </div>
      </div>
    );
  };


  if (!summaryData || loadingSummary) {
    return <div className="analytics-loading">Loading Analytics Data...</div>;
  }

  return (
    <div className="analytics-page">
      <div className="analytics-header">
        <div className="analytics-header-left">
          <img src={logo} alt="Logo" className="analytics-logo" />
        </div>
        <div className="analytics-header-right">
          <h1>Analytics</h1>
          <button className="earnings-button" onClick={toggleEarningsModal} disabled={applyingForEarnings}>
            {monetizeStatus === 'complete' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} />Neocu Earn</> :
             monetizeStatus === 'progress' ? <>Verifying Earn Account</> :
             applyingForEarnings ? <><FontAwesomeIcon icon={faSpinner} spin /> Applying...</> :
             'Apply To Earn' }
          </button>
          <button className="analytics-close-button" onClick={() => navigate('/')}>
            X
          </button>
        </div>
      </div>

      <div className="analytics-container">
        <div className="sku-refresh-container">
          <div className="sku-dropdown-container">
            <select value={selectedSkuId || 'All'} onChange={handleSkuChange} className="sku-dropdown">
              {skuItems.map((sku) => (
                <option key={sku._id || 'All'} value={sku._id || 'All'}>
                  {sku.title}
                </option>
              ))}
            </select>
          </div>
          <button className="analytics-refresh-button" onClick={handleRefresh} title="Refresh Data">
            <FontAwesomeIcon icon={faRefresh} />
          </button>
        </div>


        <div className="analytics-summary-header">
          <div className="analytics-date-range">
            {formatDate(dateRange.from)} - {formatDate(dateRange.to)} ({days} Days)
          </div>
        </div>

        <div className="analytics-summary-cards">
          {actions.map((action) => (
            <div key={action} className="analytics-card" title={actionDescriptions[action]}>
              <span>{actionLabels[action]}</span>
              <p>{formatNumber(summaryData[action])}</p>
            </div>
          ))}
          {/* Display Playtime (seconds) card */}
          <div key="playSeconds" className="analytics-card" title={actionDescriptions.playSeconds}>
            <span>{actionLabels.playSeconds}</span>
            <p>{summaryData && summaryData.playSeconds !== undefined ? `${formatNumber(summaryData.playSeconds)}` : '0'}</p>
          </div>
        </div>

        <div className="analytics-date-controls">
          <div className="preset-date-ranges-dropdown">
            <select
              value={activePresetRange || ''}
              onChange={(e) => handlePresetDateRange(e.target.value)}
              className="preset-date-dropdown"
            >
              <option value="">Preset Date Ranges</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
              <option value="Year">Year</option>
              <option value="2 Days">2 Days</option>
            </select>
          </div>
          <div className="date-display-group"> {/* Group for From/To labels and inputs */}
            <div className="date-input-group">
              <label htmlFor="dateFrom">From:</label>
              <input
                type="date"
                id="dateFrom"
                name="from"
                value={tempDateRange.from || ''}
                onChange={handleDateInputChange}
              />
            </div>
            <div className="date-input-group">
              <label htmlFor="dateTo">To:</label>
              <input
                type="date"
                id="dateTo"
                name="to"
                value={tempDateRange.to || ''}
                onChange={handleDateInputChange}
              />
            </div>
            <button className="date-go-button" onClick={applyDateRange}>
              Go
            </button>
          </div>
        </div>

        <div className="analytics-action-buttons">
          {actions.map((action) => (
            <button
              key={action}
              className={`analytics-action-button ${
                isCompareMode ? (comparedActions.includes(action) ? 'active' : '') : selectedAction === action ? 'active' : ''
              }`}
              onClick={() => handleActionClick(action)}
            >
              {actionLabels[action]}
              {isCompareMode && (
                <input
                  type="checkbox"
                  checked={comparedActions.includes(action)}
                  readOnly
                  style={{ marginLeft: '5px', pointerEvents: 'none' }}
                />
              )}
            </button>
          ))}
        </div>

        <div className="chart-header">
          <h3>{isCompareMode ? 'Comparing Actions Over Time' : `${actionLabels[selectedAction]} Over Time`}</h3>
          <div className="chart-header-right">
            <button className="compare-button" onClick={toggleCompareMode}>
              {isCompareMode ? 'Single Action' : 'Compare'}
            </button>
            <div className="chart-type-dropdown">
              <select
                value={chartType}
                onChange={(e) => handleChartTypeChange(e.target.value)}
                className="chart-type-select"
              >
                <option value="line"><FontAwesomeIcon icon={faChartLine} /> Line</option>
                <option value="bar"><FontAwesomeIcon icon={faChartBar} /> Bar</option>
                <option value="area"><FontAwesomeIcon icon={faChartArea} /> Area</option>
              </select>
            </div>
          </div>
        </div>

        <div className="analytics-chart">{renderChart()}</div>
      </div>
      {renderEarningsModal()}
    </div>
  );
};

export default Analytics;