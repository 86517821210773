import React, { useState, useEffect, useRef } from 'react'; // Removed Suspense, lazy
import './Studio.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import Console from './Console'; // Import the Console component
import logo from '../logo.png'; // Import logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons'; // Import faSave and faCheck
import MonacoEditor from 'react-monaco-editor'; // Import Monaco Editor


// --- Simple Icon Placeholders (Remove these as we are using FontAwesome) ---
const IconShare = () => <span className="studio-icon">🔗</span>;
const IconClose = ({ onClick }) => <span className="studio-icon" onClick={onClick}>✕</span>;
const IconMaximize = () => <span className="studio-icon">⬆️</span>;
const IconMinimize = () => <span className="studio-icon">⬇️</span>;
const IconPlus = () => <span className="studio-icon">+</span>;
const IconChevronLeft = () => <span className="studio-icon">❮</span>; // Back icon
const IconChevronRight = () => <span className="studio-icon">❯</span>; // Forward icon


function Studio() {
    const { id: workspaceIdFromUrl } = useParams();
    const navigate = useNavigate();
    const { userId, isSignedIn } = useAuth();

    const [activeTab, setActiveTab] = useState('code'); // 'code', 'preview', 'spaces'
    const [isConsoleOpen, setIsConsoleOpen] = useState(true);
    const [isConsoleMaximized, setIsConsoleMaximized] = useState(false);
    const [workspaceName, setWorkspaceName] = useState('Unnamed Workspace');
    const [feedList, setFeedList] = useState([]); // For Spaces Tab
    const [loadingFeed, setLoadingFeed] = useState(false); // Loading for Spaces tab feed
    const [errorFeed, setErrorFeed] = useState(null); // Error for Spaces tab feed
    const [updatingItemId, setUpdatingItemId] = useState(null); // Track item being updated in Spaces Tab
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [newWorkspaceName, setNewWorkspaceName] = useState('');
    const [loadingWorkspace, setLoadingWorkspace] = useState(true);
    const [errorLoadingWorkspace, setErrorLoadingWorkspace] = useState(null);
    const [feedItem, setFeedItem] = useState(null);
    const [versionList, setVersionList] = useState([]); // State for version list
    const [currentVersionIndex, setCurrentVersionIndex] = useState(0); // Index for current version
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [codeEditorContent, setCodeEditorContent] = useState(''); // State for code editor content
    const [savingCode, setSavingCode] = useState(false); // Loading state for save code operation
    const monacoEditorRef = useRef(null); // Ref to Monaco editor instance
    const [isCodeEditorDirty, setIsCodeEditorDirty] = useState(false); // Track if editor content is changed


    const handleToggleConsole = () => setIsConsoleOpen(!isConsoleOpen);
    const handleToggleMaximizeConsole = () => setIsConsoleMaximized(!isConsoleMaximized);

    const handleCloseStudio = () => {
        navigate('/');
        window.location.reload(); // Force reload to refresh workspace list on root
    };

    // --- Fetch Workspace Data, then Version List ---
    useEffect(() => {
        const fetchWorkspaceData = async () => {
            setLoadingWorkspace(true);
            setErrorLoadingWorkspace(null);
            if (!isSignedIn) {
                setLoadingWorkspace(false);
                return;
            }

            try {
                const response = await fetch(`https://api.neocu.com/api/feed?user_id=${userId}&sort_condition=-1`);

                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const data = await response.json();

                if (data.isSuccess && data.feedList) {
                    if (data.feedList.length > 0) {
                        const latestFeedItem = data.feedList[0];
                        if (!workspaceIdFromUrl) {
                            navigate(`/studio/${latestFeedItem._id}`, { replace: true });
                            setFeedItem(latestFeedItem);
                            setWorkspaceName(latestFeedItem.name || 'Unnamed Workspace');
                            fetchVersionHistory(latestFeedItem._id); // Fetch version history for the latest workspace
                        } else {
                            const currentFeedItem = data.feedList.find(item => item._id === workspaceIdFromUrl);
                            if (currentFeedItem) {
                                setFeedItem(currentFeedItem);
                                setWorkspaceName(currentFeedItem.name || 'Unnamed Workspace');
                                fetchVersionHistory(workspaceIdFromUrl); // Fetch version history for the URL workspace ID
                            } else {
                                setErrorLoadingWorkspace("Workspace not found.");
                            }
                        }
                    } else {
                        setWorkspaceName('No Workspaces');
                    }
                } else {
                    setErrorLoadingWorkspace(data.message || 'Failed to load workspaces.');
                }

            } catch (error) {
                setErrorLoadingWorkspace(error.message || 'Error loading workspaces.');
                console.error("Error fetching feed data:", error);
            } finally {
                setLoadingWorkspace(false);
            }
        };

        fetchWorkspaceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, workspaceIdFromUrl, navigate, isSignedIn]);

    useEffect(() => {
        if (feedItem) setWorkspaceName(feedItem.name || 'Unnamed Workspace');
    }, [feedItem]);


    const fetchVersionHistory = async (dataId) => {
        if (!isSignedIn || !dataId) return;
        setLoadingPreview(true); // Start loading preview when fetching version history
        try {
            const response = await fetch(`https://api.neocu.com/api/list?data_id=${dataId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.listList) {
                setVersionList(data.listList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))); // Sort by date, latest first
                setCurrentVersionIndex(0); // Reset to latest version
            } else {
                setVersionList([]);
                console.error('Version API Error:', data);
            }
        } catch (error) {
            console.error('Error fetching version history:', error);
            setVersionList([]);
        } finally {
            setLoadingPreview(false); // End loading preview after fetching version history
        }
    };

    useEffect(() => {
        // Load preview content whenever versionList or currentVersionIndex changes
        const loadCodeContent = async () => {
            if (versionList.length > 0 && versionList[currentVersionIndex]?.raw_code) {
                try {
                    // Directly use raw_code from versionList
                    setCodeEditorContent(versionList[currentVersionIndex].raw_code);
                    setIsCodeEditorDirty(false); // Content loaded, consider it not dirty initially
                } catch (error) {
                    console.error("Error loading raw_code content:", error);
                    setCodeEditorContent(`Error loading code: ${error.message}`); // Display error in editor
                }
            } else {
                setCodeEditorContent(''); // Clear code editor if no versions or no raw_code
                setIsCodeEditorDirty(false); // No content, not dirty
            }
        };

        loadCodeContent();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [versionList, currentVersionIndex]);


    const handleVersionNav = (direction) => {
        let newIndex = currentVersionIndex + direction;
        if (newIndex < 0) newIndex = 0;
        if (newIndex >= versionList.length) newIndex = versionList.length - 1;
        setCurrentVersionIndex(newIndex);
    };

    const handleCodeChange = (value) => {
        setCodeEditorContent(value);
        setIsCodeEditorDirty(true); // Mark editor as dirty when content changes
    };

    const handleEditorDidMount = (editor, monaco) => {
        monacoEditorRef.current = editor;
    }


    const handleSaveCode = async () => {
        if (!isCodeEditorDirty) {
            alert("No changes to save.");
            return;
        }

        if (versionList.length === 0) {
            alert("No version available to save."); // Or handle appropriately if no versions yet
            return;
        }

        const currentVersion = versionList[currentVersionIndex];
        if (!currentVersion || !currentVersion._id) {
            alert("Current version ID is not valid.");
            return;
        }

        setSavingCode(true);
        try {
            const response = await fetch(`https://api.neocu.com/api/list/${currentVersion._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ raw_code: codeEditorContent }), // Use codeEditorContent which is updated by Monaco
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to save code: ${errorData.message || response.statusText}`);
            }

            const data = await response.json();
             if (data.isSuccess) { // Correctly check for data.isSuccess
                // Optimistically update the current version in versionList with the new html_file if returned
                if (data.listItem) { // Check if listItem is in response
                    const updatedVersionList = [...versionList];
                    updatedVersionList[currentVersionIndex] = data.listItem;
                    setVersionList(updatedVersionList);
                }
                setIsCodeEditorDirty(false); // Mark as not dirty after successful save
                alert("Code saved successfully!"); // Provide user feedback
            } else {
                throw new Error(data.message || 'Failed to save code.'); // Re-throw for error handling
            }


        } catch (error) {
            console.error("Error saving code:", error);
            alert(`Error saving code: ${error.message}`); // User feedback for error
        } finally {
            setSavingCode(false);
        }
    };


    // --- Spaces Tab Functionality (Fetching Feed List) ---
    const fetchSpacesFeedData = async () => {
        setLoadingFeed(true);
        setErrorFeed(null);
        if (!isSignedIn) {
            setLoadingFeed(false);
            setFeedList([]);
            return;
        }

        try {
            const response = await fetch(`https://api.neocu.com/api/feed?user_id=${userId}&sort_condition=-1`);

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();

            if (data.isSuccess) {
                if (data.feedList) {
                    setFeedList(data.feedList);
                } else {
                    setFeedList([]);
                }
            } else {
                setErrorFeed(data.message || 'Failed to fetch workspaces.');
                setFeedList([]);
            }

        } catch (e) {
            setErrorFeed(e.message || 'Error fetching workspaces');
            setFeedList([]);
            console.error("Workspace Fetch error:", e);
        } finally {
            setLoadingFeed(false);
        }
    };

    useEffect(() => {
        if (activeTab === 'spaces') {
            fetchSpacesFeedData();
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, userId, isSignedIn]);


    const handleWorkspaceClick = (feedItem) => {
        setActiveTab('code'); // Switch back to code tab when workspace is selected
        navigate(`/studio/${feedItem._id}`);
    };

    const handleEditName = (event, feedItem) => {
        event.stopPropagation();
        setUpdatingItemId(feedItem._id);
    };

    const handleNameInputChange = (e) => setNewWorkspaceName(e.target.value);

    const handleSaveName = async (event, feedItem) => {
        event.stopPropagation();

        const newName = newWorkspaceName.trim();
        if (!newName || newName === feedItem.name) {
            setUpdatingItemId(null);
            return;
        }

        setUpdatingItemId(feedItem._id);
        try {
            const response = await fetch(`https://api.neocu.com/api/feed/${feedItem._id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: newName }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to update name: ${errorData.message || response.statusText}`);
            }

            setFeedList(currentFeedList =>
                currentFeedList.map(item =>
                    item._id === feedItem._id ? { ...item, name: newName } : item
                )
            );
            if (feedItem._id === workspaceIdFromUrl) {
                setWorkspaceName(newName);
                setFeedItem({...feedItem, name: newName});
            }

            setUpdatingItemId(null);
            setNewWorkspaceName('');

        } catch (error) {
            console.error("Error updating workspace name:", error);
            setErrorFeed(`Error updating workspace name: ${error.message}`);
            setUpdatingItemId(null);
        }
    };

    const handleDeleteWorkspace = async (event, feedItem) => {
        event.stopPropagation();
        if (!window.confirm(`Are you sure you want to delete "${feedItem.name || 'Unnamed'}"?`)) return;

        setUpdatingItemId(feedItem._id);
        try {
            const response = await fetch(`https://api.neocu.com/api/feed/${feedItem._id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to delete workspace: ${errorData.message || response.statusText}`);
            }

            setFeedList(currentFeedList => currentFeedList.filter(item => item._id !== feedItem._id));
            if (feedItem._id === workspaceIdFromUrl) {
                navigate('/studio', { replace: true });
            }
            setUpdatingItemId(null);

        } catch (error) {
            console.error("Error deleting workspace:", error);
            setErrorFeed(`Error deleting workspace: ${error.message}`);
            setUpdatingItemId(null);
        }
    };

    const handleCreateNewWorkspace = () => setIsCreatingNew(true);

    const handleCreateWorkspaceConfirm = async () => {
        const workspaceName = newWorkspaceName.trim();
        if (!workspaceName) {
            alert("Workspace name cannot be empty.");
            return;
        }

        setIsCreatingNew(false);
        setLoadingFeed(true);
        setErrorFeed(null);

        try {
            const response = await fetch(`https://api.neocu.com/api/feed`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: workspaceName, user_id: userId }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to create workspace: ${errorData.message || response.statusText}`);
            }

            const data = await response.json();
            if (data.isSuccess && data.feedItem) {
                setActiveTab('code');
                navigate(`/studio/${data.feedItem._id}`); // Adjust studio URL to new space
            } else {
                setErrorFeed(data.message || 'Failed to create workspace.');
            }


        } catch (error) {
            setErrorFeed(error.message || 'Error creating workspace.');
            console.error("Error creating workspace:", error);
        } finally {
            setLoadingFeed(false);
            setNewWorkspaceName('');
        }
    };

    const handleCreateWorkspaceCancel = () => setIsCreatingNew(false);

    const renderFeedCard = (feedItem) => (
        <div key={feedItem._id} className="feed-card" style={{ width: 'calc(50% - 15px)' }} onClick={() => handleWorkspaceClick(feedItem)}> {/* Adjusted width for 2 columns and gap */}
            <div className="feed-actions">
                <div className="feed-edit-icon" onClick={(e) => handleEditName(e, feedItem)}>✏️</div>
                <div className="feed-delete-icon" onClick={(e) => handleDeleteWorkspace(e, feedItem)}>✖</div>
            </div>
            {updatingItemId === feedItem._id ? (
                <div className="feed-card-content edit-mode">
                    <input
                        type="text"
                        className="feed-card-name-input"
                        defaultValue={feedItem.name || 'Unnamed Workspace'}
                        onChange={handleNameInputChange}
                        onKeyDown={(e) => e.key === 'Enter' && handleSaveName(e, feedItem)}
                        onBlur={() => setUpdatingItemId(null)}
                        autoFocus
                        onFocus={(e) => e.target.select()}
                    />
                </div>
            ) : (
                <div className="feed-card-content">
                    <span className="feed-card-name">{feedItem.name || 'Unnamed Workspace'}</span>
                </div>
            )}
            <div className="feed-date">
                {formatDate(feedItem.date)}
            </div>
        </div>
    );

    const renderAnonymousCard = () => (
        <div className="feed-card anonymous-card">
            <div className="feed-card-content">
                <div className="neo-orange-text feed-card-title">Workspaces</div>
                <div className="feed-card-description">Organize, plan, create, and collaborate on your 3D creations with workspaces.</div>
                <div className="feed-button-container">
                    <button className="feed-card-button signup-button" onClick={() => window.Clerk.openSignUp()}>Sign Up</button>
                    <button className="feed-card-button login-button" onClick={() => window.Clerk.openSignIn()}>Log In</button>
                </div>
            </div>
        </div>
    );

    const renderAddWorkspaceCard = () => (
        <div className="feed-card add-workspace-card">
            <div className="feed-card-content">
                <div className="feed-card-title">No Workspaces Yet</div>
                <div className="feed-card-description">Create your first workspace using the "+" button above.</div>
            </div>
        </div>
    );

    const formatDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
        } catch (e) {
            console.warn("Error parsing date:", dateString, e);
            return 'Invalid date';
        }
    };


    if (loadingWorkspace) {
        return <div className="studio-container"><div className="loading-indicator">Loading Workspace...</div></div>;
    }

    if (errorLoadingWorkspace) {
        return <div className="studio-container"><div className="error-message">Error: {errorLoadingWorkspace}</div></div>;
    }


    return (
        <div className="studio-container">
            {/* Create Workspace Modal */}
            {isCreatingNew && (
                <div className="create-workspace-modal">
                    <div className="modal-content">
                        <span className="modal-close-button" onClick={handleCreateWorkspaceCancel}>×</span>
                        <h3>Create New Workspace</h3>
                        <input
                            type="text"
                            placeholder="Workspace Name"
                            className="modal-input"
                            value={newWorkspaceName}
                            onChange={(e) => setNewWorkspaceName(e.target.value)}
                        />
                        <div className="modal-button-group">
                            <button className="modal-confirm-button" onClick={handleCreateWorkspaceConfirm}>Create</button>
                            <button className="modal-cancel-button" onClick={handleCreateWorkspaceCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Top Header Bar */}
            <header className="studio-header">
                <div className="studio-header-left">
                    <img src={logo} alt="Logo" className="studio-logo" /> {/* Logo here */}
                    <span className="studio-title">{workspaceName}</span>
                </div>
                <div className="studio-header-center">
                    <button
                        className={`studio-tab ${activeTab === 'spaces' ? 'active' : ''}`}
                        onClick={() => setActiveTab('spaces')}
                        style={activeTab === 'spaces' ? { backgroundColor: 'orange', color: 'black' } : {}}
                    >
                        Spaces{activeTab === 'spaces' && <span className="tab-checkmark"><FontAwesomeIcon icon={faCheck} /></span>}
                    </button>
                    <button
                        className={`studio-tab ${activeTab === 'code' ? 'active' : ''}`}
                        onClick={() => setActiveTab('code')}
                        style={activeTab === 'code' ? { backgroundColor: 'orange', color: 'black' } : {}}
                    >
                        Code{activeTab === 'code' && <span className="tab-checkmark"><FontAwesomeIcon icon={faCheck} /></span>}
                    </button>
                    <button
                        className={`studio-tab ${activeTab === 'preview' ? 'active' : ''}`}
                        onClick={() => setActiveTab('preview')}
                        style={activeTab === 'preview' ? { backgroundColor: 'orange', color: 'black' } : {}}
                    >
                        Preview{activeTab === 'preview' && <span className="tab-checkmark"><FontAwesomeIcon icon={faCheck} /></span>}
                    </button>
                </div>
                <div className="studio-header-right">
                    <div className="version-nav">
                        <button onClick={() => handleVersionNav(-1)} disabled={currentVersionIndex <= 0} className="version-nav-button"><IconChevronLeft /></button>
                        <span className="version-number">Version {versionList.length > 0 ? versionList.length - currentVersionIndex : 0}</span>
                        <button onClick={() => handleVersionNav(1)} disabled={currentVersionIndex >= versionList.length - 1} className="version-nav-button"><IconChevronRight /></button>
                    </div>
                    <button className="studio-create-button" onClick={handleCreateNewWorkspace} title="Create New Workspace"><IconPlus /></button>
                    {isCodeEditorDirty ? (
                        <FontAwesomeIcon icon={faSave} className="studio-icon" onClick={handleSaveCode} title="Save Changes" style={{cursor: 'pointer'}} />
                    ) : (
                        <FontAwesomeIcon icon={faCheck} className="studio-icon" style={{opacity: 0.6, color: 'green'}} title="No unsaved changes" />
                    )}
                    <IconShare />
                    <IconClose onClick={handleCloseStudio} />
                </div>
            </header>

            {/* Main Content Area */}
            <main className={`studio-main-content ${isConsoleOpen ? (isConsoleMaximized ? 'console-maximized' : 'console-open') : ''}`}>
                {activeTab === 'spaces' && (
                    <div className="spaces-tab-content">
                        <div className="spaces-header">
                            <div className="spaces-title">Spaces</div>
                        </div>
                        <div className="feed-list-container" style={{ display: 'grid', placeItems: 'center' }}> {/* Flex container for cards */}
                            {loadingFeed && <div className="loading-indicator">Loading Workspaces...</div>}
                            {errorFeed && <div className="error-message">Error: {errorFeed}</div>}
                            {!userId && renderAnonymousCard()}
                            {feedList.length === 0 && userId && !loadingFeed && !errorFeed ? renderAddWorkspaceCard() : null}
                            {feedList.map(renderFeedCard)}
                        </div>
                    </div>
                )}
                {activeTab === 'code' && (
                    <div className="studio-code-editor">
                        <MonacoEditor
                            width="100%"
                            height="calc(100% - 0px)" // Adjust height as needed to fit container
                            language="javascript" // Changed to javascript for syntax highlighting
                            theme="vs-dark" // Use vs-dark theme
                            value={codeEditorContent}
                            onChange={handleCodeChange}
                            editorDidMount={handleEditorDidMount}
                            options={{
                                selectOnLineNumbers: true,
                                minimap: { enabled: false },
                                fontSize: 14,
                                fontFamily: 'Menlo, Monaco, Consolas, Courier New, monospace',
                                lineHeight: 20,
                                wordWrap: 'on',
                                tabSize: 2,
                                lineNumbers: 'on', // Show line numbers
                            }}
                        />
                        {savingCode && <div className="loading-indicator code-saving-indicator">Saving Code...</div>} {/* Saving indicator */}
                    </div>
                )}
                {activeTab === 'preview' && (
                    <div className="studio-preview-area">
                        {loadingPreview ? (
                            <div className="loading-indicator">Loading Preview...</div>
                        ) : versionList[currentVersionIndex]?.html_file ? (
                            <iframe
                                src={versionList[currentVersionIndex]?.html_file}
                                title="Preview"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                            />
                        ) : (
                            <p>No preview available for this version.</p>
                        )}
                    </div>
                )}
            </main>

            {/* Console Area */}
            {isConsoleOpen && (
                <footer className={`studio-console ${isConsoleMaximized ? 'maximized' : ''}`}>
                    <div className="studio-console-header">
                        <span>Console</span>
                        <div className="studio-console-icons">
                            <button onClick={handleToggleMaximizeConsole} className="studio-console-icon-btn">
                                {isConsoleMaximized ? <IconMinimize /> : <IconMaximize />}
                            </button>
                            <button onClick={handleToggleConsole} className="studio-console-icon-btn">
                                <IconClose />
                            </button>
                        </div>
                    </div>
                    <div className="studio-console-content">
                        <Console activeFeedId={workspaceIdFromUrl} setActiveTab={setActiveTab} />
                    </div>
                </footer>
            )}
            {!isConsoleOpen && (
                <button className="studio-open-console-btn" onClick={handleToggleConsole}>
                    Open Console
                </button>
            )}
        </div>
    );
}

export default Studio;