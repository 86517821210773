import React, { useState, useEffect, useRef } from 'react';
import './Console.css';
import { useAuth } from '@clerk/clerk-react';

function Console({ activeFeedId, setActiveTab }) {
    const [chatLog, setChatLog] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const chatDisplayRef = useRef(null);
    const { userId, getToken, isSignedIn } = useAuth(); // Corrected to include isSignedIn
    const [isTypingActive, setIsTypingActive] = useState(false);

    useEffect(() => {
        loadChatLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFeedId, isSignedIn]); // Re-fetch chat log when feedId or isSignedIn changes

    const loadChatLog = async () => {
        if (!activeFeedId || !isSignedIn) return; // Check isSignedIn here
        setIsLoading(true);
        try {
            const token = await getToken(); // Get token using getToken() - if still needed, otherwise remove if Clerk handles automatically
            const headers = {};
            if (token) { // Conditionally add token if getToken() provides it
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch(`https://api.neocu.com/api/chat_log?data_id=${activeFeedId}`, {
                headers: headers, // Use headers with potential Authorization
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.chatlogList) {
                setChatLog(data.chatlogList.map(log => ({ ...log, responseTypingFinished: true })));
            } else {
                setChatLog([]); // Clear or handle empty log scenario
            }
        } catch (error) {
            console.error("Error loading chat log:", error);
            setChatLog([{ _id: 'error', response: 'Error loading chat history.' }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSpark = async () => {
        const currentInput = messageInput.trim();
        if (!currentInput || isLoading || !isSignedIn) return; // Check isSignedIn

        setIsLoading(true);
        setMessageInput('');

        const userMessage = { _id: `user-${Date.now()}`, query: currentInput, tempResponse: '', responseTypingFinished: true };
        setChatLog(prevLog => [...prevLog, userMessage]);

        try {
            const token = await getToken(); // Get token - if still needed
            const headers = { 'Content-Type': 'application/json' };
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch('https://api.neocu.com/api/block/spark', {
                method: 'POST',
                headers: headers, // Use headers with potential Authorization and Content-Type
                body: JSON.stringify({ query: currentInput, data_id: activeFeedId, user_id: userId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.isSuccess && data.taskId) {
                const chatId = await pollTaskStatus(data.taskId, userMessage._id);
                if (chatId) {
                    loadFinalResponse(chatId, userMessage._id);
                } else {
                    setChatLog(prevLog => prevLog.map(msg =>
                        msg._id === userMessage._id ? { ...msg, response: 'Processing failed.', responseTypingFinished: true } : msg
                    ));
                }
            } else {
                setChatLog(prevLog => prevLog.map(msg =>
                    msg._id === userMessage._id ? { ...msg, response: data.message || 'API request failed.', responseTypingFinished: true } : msg
                ));
            }


        } catch (error) {
            console.error("Error during spark request:", error);
            setChatLog(prevLog => prevLog.map(msg =>
                msg._id === userMessage._id ? { ...msg, response: 'Network error.', responseTypingFinished: true } : msg
            ));
        } finally {
            setIsLoading(false);
        }
    };

    const pollTaskStatus = async (taskId, userMessageId) => {
        let messageId = `typing-${Date.now()}`;
        const typingMessage = { _id: messageId, response: 'Processing...', isTyping: true, tempResponse: '', messageId: messageId, userMessageId: userMessageId };
        setChatLog(prevLog => [...prevLog, typingMessage]);

        while (true) {
            try {
                const token = await getToken(); // Get token - if still needed
                const headers = {};
                if (token) {
                    headers['Authorization'] = `Bearer ${token}`;
                }
                const response = await fetch(`https://api.neocu.com/api/task/${taskId}`, {
                    headers: headers, // Use headers with potential Authorization
                });
                if (!response.ok) {
                    console.error('Task status check failed:', response.status, response.statusText);
                    setChatLog(prevLog => prevLog.filter(msg => msg.messageId !== messageId));
                    return null;
                }
                const data = await response.json();

                if (data.isSuccess && data.task) {
                    if (data.task.status === 'complete') {
                        setChatLog(prevLog => prevLog.filter(msg => msg.messageId !== messageId));
                        return data.task.chat_id;
                    } else if (data.task.status === 'progress') {
                        if (data.task.message) {
                            setChatLog(prevLog => prevLog.map(msg =>
                                msg.messageId === messageId ? { ...msg, response: data.task.message, isTyping: true } : msg
                            ));
                        }
                    } else if (data.task.status === 'error') {
                        console.error('Task failed:', data.task);
                        setChatLog(prevLog => prevLog.filter(msg => msg.messageId !== messageId));
                        return null;
                    }
                } else {
                    console.error('Unexpected task API response:', data);
                    setChatLog(prevLog => prevLog.filter(msg => msg.messageId !== messageId));
                    return null;
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
            } catch (error) {
                console.error('Error polling task status:', error);
                setChatLog(prevLog => prevLog.filter(msg => msg.messageId !== messageId));
                return null;
            }
        }
    };


    const loadFinalResponse = async (chatId, userMessageId) => {
        try {
            const token = await getToken(); // Get token - if still needed
            const headers = {};
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
            const chatLogResponse = await fetch(`https://api.neocu.com/api/chat_log/${chatId}`, {
                headers: headers, // Use headers with potential Authorization
            });
            if (!chatLogResponse.ok) {
                throw new Error(`Failed to fetch final chat log: ${chatLogResponse.status} ${chatLogResponse.statusText}`);
            }

            const chatLogData = await chatLogResponse.json();
            if (chatLogData.isSuccess && chatLogData.chatlogData) {
                const latestLog = chatLogData.chatlogData;

                let responseMessage = {
                    _id: latestLog._id,
                    query: latestLog.query,
                    response: latestLog.response || '',
                    createdAt: latestLog.createdAt,
                    version: latestLog.version,
                    version_id: latestLog.version_id,
                    version_title: latestLog.version_title,
                    tempResponse: '',
                    responseTypingFinished: true,
                    commandList: latestLog.commandList,
                    scene_id: latestLog.scene_id,
                    userMessageId: userMessageId // Associate with user message
                };
                setChatLog(prevLog => [...prevLog, responseMessage]);

                if (latestLog.commandList && Array.isArray(latestLog.commandList)) {
                    executeCommands(latestLog.commandList, userMessageId); // Pass userMessageId
                }
                if (latestLog.version) {
                    setActiveTab('preview'); // Switch to preview tab on version URL
                }

            } else {
                throw new Error('Error fetching final chat log data from API.');
            }

        } catch (error) {
            console.error('Error fetching final chat log:', error);
            setChatLog(prevLog => prevLog.map(msg =>
                msg._id === userMessageId ? { ...msg, response: 'Error fetching full response.', responseTypingFinished: true } : msg
            ));
        }
    };


    const executeCommands = (commandList, userMessageId) => {
        commandList.forEach(commandData => {
            if (commandData.command === 'SetEmbedURLCommand') {
                if (commandData.embed_url) {
                    // Assuming 'embed_url' contains the URL for embedding
                    console.log(`SetEmbedURLCommand received, URL: ${commandData.embed_url}`);
                    // Handle embed URL - for now, just log it and maybe set state to show in preview
                    // You might want to use an iframe or similar to display the content
                     setChatLog(prevLog => prevLog.map(msg =>
                        msg._id === userMessageId ? { ...msg, embedURL: commandData.embed_url } : msg
                    ));
                } else {
                    console.warn('SetEmbedURLCommand received, but embed_url is missing.', commandData);
                }
            }
             else {
                console.log(`Skipping command: ${commandData.command || 'Unknown Command'}`);
            }
        });
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !isLoading && isSignedIn) { // Check isSignedIn before allowing spark
            event.preventDefault(); // Prevent newline in input
            handleSpark();
        }
    };

    useEffect(() => {
        if (chatDisplayRef.current) {
            chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
        }
    }, [chatLog]);

    const handleInputFocus = () => setIsTypingActive(true);
    const handleInputBlur = () => setIsTypingActive(false);


    return (
        <div className="console-container">
            <div ref={chatDisplayRef} className="chat-display">
                {chatLog.map((message) => (
                    <div key={message._id} className={`message-container ${message.query ? 'query-align' : 'response-align'}`}>
                        {message.query && <div className="message query">{message.query}</div>}
                        {message.isTyping ? (
                            <div className="message response typing-indicator">
                                {message.response || 'Processing...'}
                                <span className="typing-dots">
                                    <span className="dot"></span><span className="dot"></span><span className="dot"></span>
                                </span>
                            </div>
                        ) : (
                            message.response && <div className={`message response ${message._id === 'error' ? 'error-message' : ''}`}>{message.response}</div>
                        )}
                        {message.embedURL && (
                            <div className="embed-preview">
                                <iframe
                                    title="Embed Preview"
                                    src={message.embedURL}
                                    width="100%"
                                    height="300px" // Adjust as needed
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                    </div>
                ))}
                {isLoading && !chatLog.some(msg => msg.isTyping) && (
                    <div className="message response loading-message">Loading...</div>
                )}
            </div>
            <div className="input-area">
                <textarea
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className={`message-input ${isTypingActive ? 'typing-active' : ''}`}
                    placeholder="Build 3D Experience..."
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    disabled={!isSignedIn} // Disable input if not signed in
                />
                <button onClick={handleSpark} className="send-button" disabled={isLoading || !isSignedIn}> {/* Disable button if not signed in or loading */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18" height="18"><path d="M11 15H6L13 1V9H18L11 23V15Z"/></svg>
                </button>
            </div>
        </div>
    );
}

export default Console;