// GameUpload.js
import React from 'react';
import './GameUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faGlobe, faCode, faWandSparkles, faPlusSquare, faArrowLeft, faCheckCircle, faImage } from '@fortawesome/free-solid-svg-icons';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useAuth } from "@clerk/clerk-react";
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator

const API_URL = 'https://api.neocu.com/api/feed';
const IMAGE_UPLOAD_API_URL = 'https://api.neocu.com/api/image/upload';

const GameUploadModal = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [uploadType, setUploadType] = useState(null);
    const [formData, setFormData] = useState({ title: '', description: '', url: '', file_url: '', username: '' });
    const [uploadedFileUrl, setUploadedFileUrl] = useState('');
    const [uploadStatus, setUploadStatus] = useState(null);
    const [studioGames, setStudioGames] = useState([]);
    const [selectedStudioGameId, setSelectedStudioGameId] = useState(null);
    const [submissionResult, setSubmissionResult] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(''); // State for thumbnail URL
    const [thumbnailUploadStatus, setThumbnailUploadStatus] = useState(null); // State for thumbnail upload status
    const [thumbnailPreviewUrl, setThumbnailPreviewUrl] = useState(''); // State for thumbnail preview URL

    const navigate = useNavigate();
    const { userId } = useAuth();

    const handleOptionClick = (type) => {
        setUploadType(type);
        setStep(2);
    };

    const handleBack = () => {
        if (step > 1) {
            if (step === 4 && uploadType === 'studio') {
                setStep(2); // Go back to studio games list from publish step
            } else {
                setStep(step - 1);
            }
            if (step <= 2) { // If going back to step 1
                setUploadType(null);
            }
            setSubmissionResult(null);
            setThumbnailUrl(''); // Clear thumbnail URL on back
            setThumbnailUploadStatus(null); // Clear thumbnail upload status
            setThumbnailPreviewUrl(''); // Clear thumbnail preview URL
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelfHostSubmit = async () => {
        setUploadStatus('submitting');
        if (!thumbnailUrl) {
            setThumbnailUploadStatus('thumbnail-required');
            return;
        }
        const data_id = uuidv4(); // Generate UUID
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: formData.title,
                    description: formData.description,
                    name: formData.url,
                    category: 'sku',
                    type: 'embed',
                    status: 'progress',
                    username: formData.username, // Include username
                    desktop_thumbnail: thumbnailUrl || 'none', // Include thumbnail URLs
                    mobile_thumbnail: thumbnailUrl || 'none',
                    rank: 1,
                    user_id: userId,
                    data_id: data_id // Include data_id
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setUploadStatus('success');
            setSubmissionResult(data.feedData); // Updated to data.feedData
            setStep(3);
        } catch (error) {
            console.error("Error submitting self-hosted game:", error);
            setUploadStatus('error');
        }
    };

    const handleCodeUpload = async (event) => {
        setUploadStatus('uploading');
        const file = event.target.files[0];
        if (!file) return;

        const formDataUpload = new FormData();
        formDataUpload.append('file', file);
        formDataUpload.append('user_id', userId || 'incognito');

        try {
            const response = await fetch(IMAGE_UPLOAD_API_URL, {
                method: 'POST',
                body: formDataUpload,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.data.file_url) { // Access file_url correctly
                setUploadedFileUrl(data.data.file_url);
                setFormData({ ...formData, file_url: data.data.file_url });
                setUploadStatus('uploaded');
            } else {
                setUploadStatus('upload-error');
            }
        } catch (error) {
            console.error("Error uploading code:", error);
            setUploadStatus('upload-error');
        }
    };

    const handleCodeSubmit = async () => {
         setUploadStatus('submitting');
         if (!thumbnailUrl) {
            setThumbnailUploadStatus('thumbnail-required');
            return;
        }
        const data_id = uuidv4(); // Generate UUID
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: formData.title,
                    description: formData.description,
                    name: uploadedFileUrl, // Use uploaded file URL
                    category: 'sku',
                    type: 'html',
                    status: 'progress',
                    username: formData.username, // Include username
                    desktop_thumbnail: thumbnailUrl || 'none', // Include thumbnail URLs
                    mobile_thumbnail: thumbnailUrl || 'none',
                    rank: 1,
                    user_id: userId,
                    data_id: data_id // Include data_id
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
             setUploadStatus('success');
             setSubmissionResult(data.feedData); // Updated to data.feedData
             setStep(3);
        } catch (error) {
            console.error("Error submitting code game:", error);
            setUploadStatus('error');
        }
    };

    const fetchStudioGames = useCallback(async () => {
        setUploadStatus('loading-studio-games');
        try {
            const response = await fetch(`${API_URL}?user_id=${userId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.feedList) {
                setStudioGames(data.feedList);
                setUploadStatus('studio-games-loaded');
            } else {
                setUploadStatus('studio-games-load-error');
            }
        } catch (error) {
            console.error("Error fetching studio games:", error);
            setUploadStatus('studio-games-load-error');
        }
    }, [userId]);

    const handleStudioOptionClick = () => {
        setUploadType('studio');
        setStep(2);
        fetchStudioGames();
    };

    const handleStudioGameSelect = (gameId) => {
        setSelectedStudioGameId(gameId);
        setStep(4);
    };

    const handleStudioPublishSubmit = async () => {
        if (!selectedStudioGameId) return;
         setUploadStatus('submitting');
        try {
            const response = await fetch(`${API_URL}/${selectedStudioGameId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    category: 'sku',
                    status: 'progress',
                    desktop_thumbnail: thumbnailUrl || 'none', // Include thumbnail URLs
                    mobile_thumbnail: thumbnailUrl || 'none',
                    rank: 1,
                    user_id: userId,
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
             setUploadStatus('success');
             setSubmissionResult(data.feedData); // Updated to data.feedData
             setStep(3);
        } catch (error) {
            console.error("Error publishing studio game:", error);
            setUploadStatus('error');
        }
    };

    const handleNewGameClick = () => {
        navigate('/studio');
        onClose();
    };

    const handleThumbnailUpload = async (event) => {
        setThumbnailUploadStatus('uploading');
        const file = event.target.files[0];
        if (!file) return;

        const formDataUpload = new FormData();
        formDataUpload.append('file', file);
        formDataUpload.append('user_id', userId || 'incognito');

        try {
            const response = await fetch(IMAGE_UPLOAD_API_URL, {
                method: 'POST',
                body: formDataUpload,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.data.file_url) {
                setThumbnailUrl(data.data.file_url);
                setThumbnailPreviewUrl(data.data.file_url); // Set preview URL
                setThumbnailUploadStatus('uploaded');
            } else {
                setThumbnailUploadStatus('upload-error');
            }
        } catch (error) {
            console.error("Error uploading thumbnail:", error);
            setThumbnailUploadStatus('upload-error');
        }
    };


    return (
        <div className="game-upload-modal-overlay" onClick={onClose}>
            <div className="game-upload-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="game-upload-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>

                {step === 1 && (
                    <>
                        <h2 className="game-upload-modal-title">Game Upload</h2>
                        <div className="game-upload-options">
                            <div className="game-upload-option" onClick={() => handleOptionClick('self-host')}>
                                <FontAwesomeIcon icon={faGlobe} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>Self-Host</h3>
                                    <p>Provide a URL where your game lives.</p>
                                </div>
                            </div>
                            <div className="game-upload-option" onClick={handleStudioOptionClick}>
                                <FontAwesomeIcon icon={faWandSparkles} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>Studio</h3>
                                    <p>Build and push a game from Neocu Studio.</p>
                                </div>
                            </div>
                            <div className="game-upload-option" onClick={() => handleOptionClick('code')}>
                                <FontAwesomeIcon icon={faCode} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>Code</h3>
                                    <p>Upload code straight into the Neocu platform.</p>
                                </div>
                            </div>
                            <div className="game-upload-option" onClick={handleNewGameClick}>
                                <FontAwesomeIcon icon={faPlusSquare} className="game-upload-option-icon" style={{ color: '#f60' }} />
                                <div className="game-upload-option-text">
                                    <h3>New</h3>
                                    <p>Generate and refine a game from scratch.</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {step === 2 && uploadType === 'self-host' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Self-Host</h2>
                        <div className="game-upload-form">
                            <input type="text" name="title" placeholder="Title" className="game-upload-input" onChange={handleInputChange} required />
                            <textarea name="description" placeholder="Description" className="game-upload-textarea" onChange={handleInputChange} required></textarea>
                            <input type="url" name="url" placeholder="URL" className="game-upload-input" onChange={handleInputChange} required />
                            <input type="text" name="username" placeholder="Public Username" className="game-upload-input" onChange={handleInputChange} required />
                            <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUpload" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} required />
                                <label htmlFor="thumbnailUpload" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                {thumbnailUploadStatus === 'thumbnail-required' && <p className="upload-error-message">Thumbnail is required.</p>}
                            </div>
                             {thumbnailPreviewUrl && (
                                <div className="thumbnail-preview">
                                    <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                </div>
                            )}
                            <button className="game-upload-submit-button" onClick={handleSelfHostSubmit} disabled={uploadStatus === 'submitting' || thumbnailUploadStatus !== 'uploaded' && !thumbnailPreviewUrl}>
                                {uploadStatus === 'submitting' ? 'Submitting...' : 'Add'}
                            </button>
                            {uploadStatus === 'error' && <p className="upload-error-message">Error submitting game. Please try again.</p>}
                        </div>
                    </>
                )}

                {step === 2 && uploadType === 'code' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Code Upload</h2>
                        <div className="game-upload-form">
                            <input type="text" name="title" placeholder="Title" className="game-upload-input" onChange={handleInputChange} required />
                            <textarea name="description" placeholder="Description" className="game-upload-textarea" onChange={handleInputChange} required></textarea>
                            <input type="text" name="username" placeholder="Public Username" className="game-upload-input" onChange={handleInputChange} required />
                            <div className="game-upload-file-upload">
                                <input type="file" id="codeUpload" className="game-upload-file-input" onChange={handleCodeUpload} disabled={uploadStatus === 'uploading'} required />
                                <label htmlFor="codeUpload" className="game-upload-file-label">
                                    {uploadStatus === 'uploading' ? 'Uploading...' : (uploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Code')}
                                </label>
                                {uploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading code.</p>}
                            </div>
                             <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUpload" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} required />
                                <label htmlFor="thumbnailUpload" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                {thumbnailUploadStatus === 'thumbnail-required' && <p className="upload-error-message">Thumbnail is required.</p>}
                            </div>
                             {thumbnailPreviewUrl && (
                                <div className="thumbnail-preview">
                                    <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                </div>
                            )}
                            <button className="game-upload-submit-button" onClick={handleCodeSubmit} disabled={uploadStatus === 'submitting' || uploadStatus !== 'uploaded' || thumbnailUploadStatus !== 'uploaded' && !thumbnailPreviewUrl}>
                                {uploadStatus === 'submitting' ? 'Submitting...' : 'Add'}
                            </button>
                            {uploadStatus === 'error' && <p className="upload-error-message">Error submitting game. Please try again.</p>}
                        </div>
                    </>
                )}
                {step === 2 && uploadType === 'thumbnail' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Thumbnail Upload</h2>
                        <div className="game-upload-form">
                            <div className="game-upload-file-upload">
                                <input type="file" id="thumbnailUploadStep2" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} />
                                <label htmlFor="thumbnailUploadStep2" className="game-upload-file-label">
                                    {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail')}
                                </label>
                                {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                            </div>
                             {thumbnailUploadStatus === 'uploaded' && thumbnailUrl && (
                                <div className="thumbnail-preview">
                                    <h3>Thumbnail Preview</h3>
                                    <img src={thumbnailUrl} alt="Thumbnail Preview" style={{maxWidth: '100%', height: 'auto'}} />
                                </div>
                            )}
                        </div>
                    </>
                )}


                {step === 2 && uploadType === 'studio' && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Studio Games</h2>
                        <div className="studio-games-list">
                            {uploadStatus === 'loading-studio-games' && <p>Loading games...</p>}
                            {uploadStatus === 'studio-games-load-error' && <p>Error loading games.</p>}
                            {uploadStatus === 'studio-games-loaded' && studioGames.length === 0 && <p>No games found in your studio.</p>}
                            {uploadStatus === 'studio-games-loaded' && studioGames.map(game => (
                                <div key={game._id} className="studio-game-card" onClick={() => handleStudioGameSelect(game._id)}>
                                    <h3>{game.title}</h3>
                                </div>
                            ))}
                        </div>
                         {uploadType === 'studio' && selectedStudioGameId && step === 2 && (
                            <div className="game-upload-form">
                                 <div className="game-upload-file-upload">
                                    <input type="file" id="studioThumbnailUpload" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} />
                                    <label htmlFor="studioThumbnailUpload" className="game-upload-file-label">
                                        {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail (Optional)')}
                                    </label>
                                    {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                </div>
                                {thumbnailPreviewUrl && (
                                    <div className="thumbnail-preview">
                                        <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {step === 3 && submissionResult && (
                    <div className="submission-success-container">
                        <h2 className="game-upload-modal-title">Game in Review</h2>
                        <p>Your game is now in review. Check the Studio for updates.</p>
                        <div className="submission-actions">
                            <button className="game-upload-button studio-button" style={{ backgroundColor: '#f60', color: '#ffffff' }} onClick={() => { navigate('/studio'); onClose(); }}>Studio</button>
                            {submissionResult._id && ( // Use data_id for public link
                                <div className="public-link-card">
                                    <p>Public Link</p>
                                    <a href={`/${submissionResult._id}`} target="_blank" rel="noopener noreferrer" className="public-link" style={{ color: '#f60' }}>
                                        {`${window.location.origin}/${submissionResult._id}`}
                                    </a>
                                </div>
                            )}
                            <button className="game-upload-button close-button" onClick={onClose}>Close</button>
                        </div>
                    </div>
                )}

                {step === 4 && uploadType === 'studio' && selectedStudioGameId && (
                    <>
                        <button className="game-upload-modal-back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <h2 className="game-upload-modal-title">Publish Game</h2>
                        <p>You are making "{studioGames.find(game => game._id === selectedStudioGameId)?.title}" public.</p>
                        <div className="confirmation-actions">
                            <button className="game-upload-submit-button" onClick={handleStudioPublishSubmit} disabled={uploadStatus === 'submitting'}>
                                {uploadStatus === 'submitting' ? 'Publishing...' : 'Add'}
                            </button>
                            {uploadStatus === 'error' && <p className="upload-error-message">Error publishing game. Please try again.</p>}
                        </div>
                         {uploadType === 'studio' && selectedStudioGameId && step === 4 && (
                            <div className="game-upload-form">
                                 <div className="game-upload-file-upload">
                                    <input type="file" id="studioThumbnailUploadStep4" className="game-upload-file-input" onChange={handleThumbnailUpload} disabled={thumbnailUploadStatus === 'uploading'} />
                                    <label htmlFor="studioThumbnailUploadStep4" className="game-upload-file-label">
                                        {thumbnailUploadStatus === 'uploading' ? 'Uploading...' : (thumbnailUploadStatus === 'uploaded' ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} /> Saved</> : 'Upload Thumbnail (Optional)')}
                                    </label>
                                    {thumbnailUploadStatus === 'upload-error' && <p className="upload-error-message">Error uploading thumbnail.</p>}
                                </div>
                                {thumbnailPreviewUrl && (
                                    <div className="thumbnail-preview">
                                        <img src={thumbnailPreviewUrl} alt="Thumbnail Preview" style={{maxWidth: '50px', height: 'auto'}} />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

            </div>
        </div>
    );
};

export default GameUploadModal;