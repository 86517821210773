import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles, faDollarSign, faGamepad, faPlay, faUserCircle, faXmark, faBars, faFlag, faStar as faStarOutline, faShareAlt, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Feed.css';
import logo from './../logo.png';
import { SignInButton, SignUpButton, SignOutButton, useUser, UserProfile, useAuth } from "@clerk/clerk-react"; // Import useAuth
import GameUploadModal from './GameUpload'; // Import GameUploadModal

const API_URL = 'https://api.neocu.com/api/feed/games?category=sku&status=ready';
const ADVISOR_API_URL = 'https://api.neocu.com/api/advisor'; // Correct API URL for advisor actions, including play time updates
const IP_LOOKUP_URL = 'https://api.ipify.org?format=json';
const CONSUMER_API_URL = 'https://api.neocu.com/api/organization';
const FEED_PUT_API_URL = 'https://api.neocu.com/api/feed'; // Define PUT API URL - Not used for play time anymore, can be removed if not used elsewhere
const DEFAULT_VIDEO_THUMBNAIL = 'https://storage.googleapis.com/download/storage/v1/b/fussydata/o/8a09944732110f1b82f2823a89dea617.jpg?generation=1743723719767639&alt=media';
const DEFAULT_GAME_ICON = 'https://storage.googleapis.com/download/storage/v1/b/fussydata/o/c735e147cc54afb6392b7d2e253df6bb.png?generation=1744023210535825&alt=media';


// Improved DosPlayer Component
const DosPlayer = (props) => {
    const containerRef = useRef(null);
    const [error, setError] = useState(null);
    const instanceId = useRef(`dos-instance-${Date.now()}`);
    const dosInstance = useRef(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const progressIntervalRef = useRef(null); // Ref to hold interval ID

    useEffect(() => {
        // Check if container is ready
        if (!containerRef.current) return;

        // Clear any existing content in the container
        containerRef.current.innerHTML = '';

        console.log(`Initializing DOS instance ${instanceId.current} for ${props.bundleUrl}`);

        // Make sure emulators is properly configured
        if (window.emulators) {
            window.emulators.pathPrefix = "/";
        } else {
            console.error("emulators object not available - js-dos.js may not be loaded correctly");
            setError("DOS emulator not available");
            return;
        }

        // Make sure Dos constructor exists
        if (typeof window.Dos !== 'function') {
            console.error("Dos constructor not found - js-dos.js may not be loaded correctly");
            setError("DOS emulator not initialized");
            return;
        }

        try {
            // Initialize DOS
            const dos = window.Dos(containerRef.current, {
              // Disable all mobile controls explicitly
                mobileControls: false,
                noMobileControls: true,
                controlsConfig: {
                    enabled: false,
                    disableVisibleOnMobile: true,
                    disableFullscreen: true,
                    disableMouseGrab: true
                }
            });
            dosInstance.current = dos;

            // Run the bundle
            if (props.bundleUrl) {
                let bundleUrlToLoad = props.bundleUrl;

                if (bundleUrlToLoad.endsWith('.jsdos')) {
                    const jsdosIndex = bundleUrlToLoad.indexOf('.jsdos');
                    if (jsdosIndex !== -1) {
                        const potentialQuestionMarkIndex = bundleUrlToLoad.indexOf('?', jsdosIndex + 6); // Check for '?' after '.jsdos'
                        if (potentialQuestionMarkIndex !== -1) {
                            bundleUrlToLoad = bundleUrlToLoad.substring(0, potentialQuestionMarkIndex + 6); // Include '.jsdos' in substring
                        }
                    }
                }

                // Add cache-busting parameter
                const bundleUrlWithCache = `${bundleUrlToLoad}${bundleUrlToLoad.includes('?') ? '&' : '?'}_t=${Date.now()}`;

                // Show loading overlay
                setShowLoadingOverlay(true);
                setLoadingProgress(0); // Reset progress

                const loadingStartTime = Date.now();
                progressIntervalRef.current = setInterval(() => { // Store interval ID in ref
                    const elapsedTime = Date.now() - loadingStartTime;
                    const progress = Math.min(100, (elapsedTime / 7000) * 100); // 7 seconds max duration
                    setLoadingProgress(progress);
                    if (progress >= 100) {
                        clearInterval(progressIntervalRef.current); // Clear interval using ref
                        progressIntervalRef.current = null; // Clear ref
                        setTimeout(() => setShowLoadingOverlay(false), 500); // Small delay before hiding
                    }
                }, 100);


                // Run the bundle
                dos.run(bundleUrlWithCache)
                    .then(() => {
                        clearInterval(progressIntervalRef.current); // Clear interval using ref
                        progressIntervalRef.current = null; // Clear ref
                        setTimeout(() => setShowLoadingOverlay(false), 500);
                        console.log(`Bundle ${props.bundleUrl} loaded successfully`);
                        if (props.onDosInstanceCreated) {
                            props.onDosInstanceCreated(dos);
                        }
                    })
                    .catch(err => {
                        clearInterval(progressIntervalRef.current); // Clear interval using ref
                        progressIntervalRef.current = null; // Clear ref
                        setTimeout(() => setShowLoadingOverlay(false), 500);
                        console.error(`Error running DOS bundle:`, err);
                        setError(`Failed to load game: ${err.message}`);
                    });
            }
        } catch (err) {
            clearInterval(progressIntervalRef.current); // Ensure interval is cleared in case of sync error
            progressIntervalRef.current = null; // Clear ref
            setTimeout(() => setShowLoadingOverlay(false), 500);
            console.error(`Error initializing DOS:`, err);
            setError(`Error setting up game: ${err.message}`);
        }

        // Cleanup function
        return () => {
            if (progressIntervalRef.current) { // Check if interval ref has a value
                clearInterval(progressIntervalRef.current); // Clear interval using ref
                progressIntervalRef.current = null; // Clear ref
            }
            if (dosInstance.current) {
                try {
                    console.log(`Cleaning up DOS instance ${instanceId.current}`);
                    if (typeof dosInstance.current.exit === 'function') {
                        dosInstance.current.exit();
                    }

                    if (props.onDosInstanceExited) {
                        props.onDosInstanceExited();
                    }
                } catch (err) {
                    console.error(`Error cleaning up DOS instance:`, err);
                }
                dosInstance.current = null;
            }

            // Clear the container
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, [props.bundleUrl, props.onDosInstanceCreated, props.onDosInstanceExited]);

    if (error) {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#000", color: "#fff" }}>
                <div style={{ marginBottom: "20px" }}>Error: {error}</div>
                <button onClick={() => window.location.reload()} style={{ padding: "10px 20px", backgroundColor: "#f60", color: "#fff", border: "none", borderRadius: "4px", cursor: "pointer" }}>
                    Reload
                </button>
            </div>
        );
    }

    return (
        <div
            ref={containerRef}
            style={{ width: "100%", height: "100%", backgroundColor: "#000", position: 'relative' }}
            className="dos-container"
        >
            {showLoadingOverlay && (
                <div className="dos-loading-overlay">
                    <img src={logo} alt="Logo" className="dos-loading-logo" />
                    <div className="dos-loading-progress-bar">
                        <div className="dos-loading-progress" style={{ width: `${loadingProgress}%` }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

function Feed() {
    const [feedItems, setFeedItems] = useState([]);
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaginating, setIsPaginating] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [initialIdLoad, setInitialIdLoad] = useState(true);
    const [notification, setNotification] = useState({ visible: false, message: '' });
    const [slideDirection, setSlideDirection] = useState('');
    const [userIp, setUserIp] = useState(null);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [dosKey, setDosKey] = useState(Date.now()); // Add key for DosPlayer to force remount
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [reportReason, setReportReason] = useState('');
    const [isVideoButtonModalOpen, setIsVideoButtonModalOpen] = useState(false);
    const [isProModalOpen, setIsProModalOpen] = useState(false);
    const [favoriteStates, setFavoriteStates] = useState({});
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [scrollCount, setScrollCount] = useState(0); // Scroll counter for logged out users
    const [isGameUploadModalOpen, setIsGameUploadModalOpen] = useState(false); // State for GameUploadModal
    const [playTimerIntervalId, setPlayTimerIntervalId] = useState(null);
    const [secondsPlayed, setSecondsPlayed] = useState(0);
    const [currentGameId, setCurrentGameId] = useState(null);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [isIdlePaused, setIsIdlePaused] = useState(false); // Removed isIdlePaused
    const [searchResults, setSearchResults] = useState([]);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isGameModalOpen, setIsGameModalOpen] = useState(false);


    const dropdownRef = useRef(null);
    const dosbox = useRef(null);
    const { isSignedIn, user } = useUser();
    const { userId } = useAuth(); // Use useAuth to get userId

    const { id: routeId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const isMounted = useRef(true);
    const notificationTimeout = useRef(null);
    const searchInputRef = useRef(null);
    const searchResultsRef = useRef(null);


    // Callback to get Dos instance from DosPlayer
    const handleDosInstanceCreated = useCallback((instance) => {
        console.log("DOS instance created and stored in ref");
        dosbox.current = instance;
    }, []);

    // Callback when DosPlayer exits
    const handleDosInstanceExited = useCallback(() => {
        console.log("DOS instance exited");
        dosbox.current = null;
    }, []);

    // Notification Helper (define before its usage in useCallback)
    const showNotification = useCallback((message) => {
        clearTimeout(notificationTimeout.current);
        setNotification({ visible: true, message });
        notificationTimeout.current = setTimeout(() => {
            if (isMounted.current) setNotification({ visible: false, message: '' });
        }, 2500);
    }, [isMounted]);

    // Close Report Modal (define before its usage in useCallback)
    const closeReportModal = useCallback(() => setIsReportModalOpen(false), [setIsReportModalOpen]);

    const sendPlayTimeUpdate = useCallback(async (feedId, currentSeconds) => {
        if (!feedId) {
            console.warn("No feedId to update play time for.");
            return;
        }

        const startTime = new Date();
        console.log(`[${startTime.toLocaleTimeString()}] Starting play time update for feedId: ${feedId}, seconds: ${currentSeconds}`);

        try {
            const response = await fetch(`${ADVISOR_API_URL}/${feedId}`, { // Changed API URL to ADVISOR_API_URL
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'playtime', seconds: currentSeconds, active: true }), // Removed isIdlePaused logic and always send active: true, added action: 'playtime'
            });
            if (!response.ok) {
                console.error(`Failed to update play time for ${feedId} with status: ${response.status}`);
            } else {
                const updatedData = await response.json();
                const endTime = new Date();
                console.log(`[${endTime.toLocaleTimeString()}] Play time updated successfully for feedId: ${feedId}, seconds: ${currentSeconds}`, updatedData);
                // Handle updated data if needed
            }
        } catch (error) {
            console.error("Error updating play time:", error);
        }
    }, []); // Removed isIdlePaused dependency


    // Report Action Function (use closeReportModal and showNotification which are now defined)
    const reportAction = useCallback(async (feedId, reason) => {
        if (!feedId || !reason) {
            console.warn(`Feed ID or Report Reason missing, cannot report. feedId: ${feedId}, reason: ${reason}`);
            return;
        }
        const payload = { action: 'report', sku_id: feedId, event_result: reason, user_id: userId || 'incognito' }; // Use userId from useAuth
        try {
            const response = await fetch(ADVISOR_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                console.error(`Advisor API report failed with status: ${response.status}`);
                showNotification("Report submission failed.");
            } else {
                showNotification("Report submitted successfully!");
                closeReportModal();
            }
        } catch (error) {
            console.error(`Error submitting report:`, error);
            showNotification("Error submitting report.");
        }
    }, [userId, showNotification, closeReportModal]); // userId dependency

    // Favorite Action Function
    const favoriteAction = useCallback(async (feedId, currentFavorite) => {
        if (!feedId) {
            console.warn(`Feed ID missing, cannot favorite. feedId: ${feedId}`);
            return;
        }
        const actionType = currentFavorite ? 'unfavorite' : 'favorite';
        const payload = { action: actionType, sku_id: feedId, user_id: userId || 'incognito' }; // Use userId from useAuth
        try {
            const response = await fetch(ADVISOR_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                console.error(`Advisor API favorite action failed with status: ${response.status}`);
                showNotification("Favorite action failed.");
            } else {
                setFavoriteStates(prevState => ({
                    ...prevState,
                    [feedId]: !currentFavorite,
                }));
            }
        } catch (error) {
            console.error(`Error performing favorite action:`, error);
            showNotification("Error performing favorite action.");
        }
    }, [userId, showNotification, setFavoriteStates]); // userId dependency

    // Tracking Function
    const trackAction = useCallback(async (actionType, feedId) => {
        if (!feedId) {
            console.warn(`User IP or Feed ID missing, cannot track action ${actionType}. userIp: ${userIp}, feedId: ${feedId}`);
            return;
        }
        const payload = { action: actionType, sku_id: feedId, ip: userIp, user_id: userId || 'incognito' };
        try {
            const response = await fetch(ADVISOR_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
                keepalive: true,
            });
            if (!response.ok) {
                console.error(`Advisor API request failed for action ${actionType} with status: ${response.status}`);
            }
        } catch (error) {
            console.error(`Error tracking action ${actionType}:`, error);
        }
    }, [userIp, userId]);


    // Fetch User Subscription Status
    const fetchSubscriptionStatus = useCallback(async () => {
        if (!isSignedIn) return null;
        try {
            const response = await fetch(`${CONSUMER_API_URL}?user_id=${userId}`);
            if (!response.ok) {
                console.error("Failed to fetch user subscription status.");
                return null;
            }
            const data = await response.json();
            // Modified check and return full data
            if (data && data.isSuccess && Array.isArray(data.organizationList) && data.organizationList.length > 0) {
                return data; // Return the entire data object
            } else {
                return null; // No organization found, or not successful, return null
            }
        } catch (error) {
            console.error("Error fetching user subscription:", error);
            return null;
        }
    }, [isSignedIn, userId]);


    // Data Fetching
    const fetchFeed = useCallback(async (page, initialLoadItemId = null) => {
        setIsLoading(true);
        setError(null);
        let initialItem = null;
        let mainFeedItems = [];

        try {
            if (initialLoadItemId) {
                const initialItemResponse = await fetch(`https://api.neocu.com/api/feed/${initialLoadItemId}`);
                if (!initialItemResponse.ok) {
                    throw new Error(`HTTP error! status: ${initialItemResponse.status} for initial item`);
                }
                const initialItemData = await initialItemResponse.json();
                if (initialItemData.isSuccess && initialItemData.feedData) { // Access feedData here
                    initialItem = initialItemData.feedData; // Access feedData here
                    setFeedItems([initialItem]); // Display initial item immediately
                    setCurrentItemIndex(0);
                    trackAction('impression', initialItem._id);
                } else {
                    console.error("Initial item API response:", initialItemData); // Log the response data here
                    throw new Error(initialItemData.message || 'Failed to fetch initial feed item.'); // Line 414 is likely where the error is thrown.
                }
            }

            const mainFeedResponse = await fetch(`${API_URL}&page=${page}&user_id=${userId || ''}`); // Use userId for feed fetch
            if (!mainFeedResponse.ok) {
                throw new Error(`HTTP error! status: ${mainFeedResponse.status} for main feed`);
            }
            const mainFeedData = await mainFeedResponse.json();

            if (isMounted.current) {
                if (mainFeedData.isSuccess && mainFeedData.feedList) {
                    mainFeedItems = mainFeedData.feedList;
                    setTotalPages(mainFeedData.totalPages);
                    setCurrentPage(page);

                    // Initialize favorite states for main feed items
                    const mainFeedFavoriteStates = {};
                    mainFeedItems.forEach(item => {
                        mainFeedFavoriteStates[item._id] = item.favorite || false;
                    });
                    setFavoriteStates(prevState => ({ ...prevState, ...mainFeedFavoriteStates }));

                    if (initialLoadItemId && initialItem) {
                        // Find the index of the initial item in the main feed if it exists
                        const initialItemIndexInMainFeed = mainFeedItems.findIndex(item => item._id === initialItem._id);
                        if (initialItemIndexInMainFeed !== -1) {
                            setFeedItems(mainFeedItems); // Replace initial single item with full feed
                            setCurrentItemIndex(initialItemIndexInMainFeed);
                        } else {
                            setFeedItems([initialItem, ...mainFeedItems]); // Prepend initial item if not found in main feed
                            setCurrentItemIndex(0); // Keep index at 0 as initial item is first
                        }
                    } else {
                        setFeedItems(mainFeedItems); // Set main feed items if no initial item ID
                        if (page === 1 && mainFeedItems.length > 0) {
                            setCurrentItemIndex(0);
                            if (location.pathname !== `/${mainFeedItems[0]._id}`) { // Corrected path for navigation from Game Modal
                                navigate(`/${mainFeedItems[0]._id}`, { replace: true });
                            }
                            trackAction('impression', mainFeedItems[0]._id);
                        }
                    }

                } else {
                    throw new Error(mainFeedData.message || 'Failed to fetch feed data.');
                }
            }
        } catch (err) {
            if (isMounted.current) {
                console.error("Fetch error:", err);
                setError('System Overload');
            }
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
                setIsPaginating(false);
            }
        }
        return true;
    }, [navigate, location.pathname, trackAction, userId, setFavoriteStates]); // userId dependency

    // Search Functionality
    const handleSearchChange = useCallback(async (event) => {
        const query = event.target.value;
        if (!query) {
            setSearchResults([]);
            return;
        }

        try {
            const response = await fetch('https://api.neocu.com/api/feed/search', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: query }),
            });
            if (!response.ok) {
                throw new Error(`Search API failed with status ${response.status}`);
            }
            const data = await response.json();
            if (data.isSuccess && data.search) {
                setSearchResults(data.search);
            } else {
                setSearchResults([]); // Clear results if search fails or no results
            }
        } catch (error) {
            console.error("Search error:", error);
            setSearchResults([]); // Clear results on error
        }
    }, []);

    const handleSearchSubmit = (event) => {
        event.preventDefault(); // Prevent form submission if within a form
        setIsSearchVisible(true);
    };

    const closeSearch = () => {
        setIsSearchVisible(false);
        setSearchResults([]);
        setIsSearchActive(false);
    };

    const handleSearchResultClick = (itemId) => {
        navigate(`/${itemId}`);
        closeSearch();
    };


    // Fetch User IP
    useEffect(() => {
        const fetchUserIp = async () => {
            try {
                const response = await fetch(IP_LOOKUP_URL);
                if (response.ok) {
                    const data = await response.json();
                    setUserIp(data.ip);
                } else {
                    console.error("Failed to fetch user IP address.");
                }
            } catch (error) {
                console.error("Error fetching user IP:", error);
            }
        };
        fetchUserIp();
    }, []);


    // Effect for Initial Load
    useEffect(() => {
        isMounted.current = true;
        setScrollCount(0); // Reset scroll count on component mount (session start)
        if (routeId && initialIdLoad) {
            console.log("Attempting initial load for ID:", routeId);
            fetchFeed(1, routeId);
            setInitialIdLoad(false);
        } else if (!routeId && initialIdLoad) {
            console.log("No ID in route, fetching page 1");
            fetchFeed(1);
            setInitialIdLoad(false);
        }
        return () => {
            isMounted.current = false;
            clearTimeout(notificationTimeout.current);
            if (dosbox.current) {
                try {
                    console.log("Cleaning up dosbox instance on unmount");
                    if (typeof dosbox.current.exit === 'function') {
                        dosbox.current.exit();
                    } else if (typeof dosbox.current.stop === 'function') {
                        dosbox.current.stop();
                    }
                } catch (err) {
                    console.error("Error exiting DOS on unmount:", err);
                }
                dosbox.current = null;
            }
            clearInterval(playTimerIntervalId); // Clear interval if component unmounts while playing
            console.log("Feed component unmounted");
        };
    }, [routeId, fetchFeed, playTimerIntervalId]);

    // Re-fetch feed when routeId changes, including navigation from Game Modal
    useEffect(() => {
        if (routeId && !initialIdLoad) {
            console.log("Route ID changed to:", routeId, "re-fetching feed.");
            fetchFeed(1, routeId); // Re-fetch feed for the new routeId
        }
    }, [routeId, fetchFeed, initialIdLoad]);


    // Clean DOS instance when switching away from playing state
    useEffect(() => {
        if (!isPlaying && dosbox.current) {
            console.log("Cleaning up dosbox instance due to isPlaying change");
            try {
                if (typeof dosbox.current.exit === 'function') {
                    dosbox.current.exit();
                } else if (typeof dosbox.current.stop === 'function') {
                    dosbox.current.stop();
                }
                dosbox.current = null;
            } catch (err) {
                console.error("Error exiting DOS on isPlaying change:", err);
            }
            dosbox.current = null;
        }
        if (!isPlaying) {
            clearInterval(playTimerIntervalId);
            setPlayTimerIntervalId(null);
            setSecondsPlayed(0);
            setCurrentGameId(null);
        }
    }, [isPlaying, playTimerIntervalId]);

    // Navigation
    const navigateItem = (newIndex, direction) => {
        const previousItem = feedItems[currentItemIndex];

        // If we have a DOS game playing, clean it up before navigating
        if (previousItem?.type === 'dos' && isPlaying && dosbox.current) {
            try {
                console.log("Cleaning up dosbox instance before navigation");
                if (typeof dosbox.current.exit === 'function') {
                    dosbox.current.exit();
                } else if (typeof dosbox.current.stop === 'function') {
                    dosbox.current.stop();
                }
                dosbox.current = null;
            } catch (err) {
                console.error("Error exiting DOS on navigate:", err);
            }
        }
        if (isPlaying) {
            clearInterval(playTimerIntervalId);
            setPlayTimerIntervalId(null);
            setSecondsPlayed(0);
            setCurrentGameId(null);
            setIsPlaying(false); // Stop playing state when navigating
        }


        // Generate a new DOS key to force remount of DosPlayer on navigation
        setDosKey(Date.now());


        if (newIndex >= 0 && newIndex < feedItems.length) {
            setSlideDirection(direction);
            setCurrentItemIndex(newIndex);

            const newItemId = feedItems[newIndex]._id;
            if (location.pathname !== `/${newItemId}`) {
                navigate(`/${newItemId}`, { replace: true });
            }
            setTimeout(() => {
                if (isMounted.current) setSlideDirection('');
            }, 500);
            if (feedItems[newIndex]) trackAction('impression', feedItems[newIndex]._id);
        } else if (newIndex >= feedItems.length && currentPage < totalPages && !isPaginating) {
            fetchFeed(currentPage + 1).then((success) => {
                if (success && isMounted.current) {
                    setSlideDirection(direction);
                    setTimeout(() => {
                        const updatedFeedItems = feedItems;
                        if (isMounted.current && newIndex < updatedFeedItems.length) {
                            setCurrentItemIndex(newIndex);
                            navigate(`/${updatedFeedItems[newIndex]._id}`, { replace: true });
                            setTimeout(() => {
                                if (isMounted.current) setSlideDirection('');
                            }, 500);
                            if (updatedFeedItems[newIndex]) trackAction('impression', updatedFeedItems[newIndex]._id);
                        } else if (isMounted.current) {
                            setSlideDirection('');
                        }
                    }, 100);
                } else if (isMounted.current) {
                    setSlideDirection('');
                }
            });
        } else {
            setSlideDirection(direction);
            setTimeout(() => {
                if (isMounted.current) setSlideDirection('');
            }, 100);
        }
    };

    const handleNext = () => {
        if (!isSignedIn && scrollCount >= 10) {
            setIsAuthModalOpen(true);
            return;
        }
        setScrollCount(prevCount => prevCount + 1);
        navigateItem(currentItemIndex + 1, 'up');
    }

    const handlePrevious = () => {
        if (!isSignedIn && scrollCount >= 10) {
            setIsAuthModalOpen(true);
            return;
        }
        setScrollCount(prevCount => prevCount + 1);
        navigateItem(currentItemIndex - 1, 'down');
    }

    const handleDownArrow = () => {
        if (!isSignedIn && scrollCount >= 10) {
            setIsAuthModalOpen(true);
            return;
        }
        setScrollCount(prevCount => prevCount + 1);
        handleNext();
    };


    // Swipe Handling
    const swipeHandlers = useSwipeable({
        onSwipedUp: handleNext,
        onSwipedDown: handlePrevious,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const handlePlayOrRequest = async () => {
        if (!isSignedIn) {
            setIsAuthModalOpen(true);
            return;
        }

        const currentItem = feedItems[currentItemIndex];
        if (currentItem?.type === 'embed' || currentItem?.type === 'embed_playr' || currentItem?.type === 'html') {
            setIsPlaying(true);
            if (currentItem && currentItem._id) trackAction('play', currentItem._id);
             // Start play time tracking for embed games as well
            const payload = { action: 'play', sku_id: currentItem._id, user_id: userId || 'incognito', ip: userIp };
            console.log("Sending POST to ADVISOR_API_URL:", ADVISOR_API_URL, payload);
            try {
                const response = await fetch(ADVISOR_API_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log("POST to ADVISOR_API_URL response:", data);
                    if (data.advisor && data.advisor._id) {
                        const advisorId = data.advisor._id;
                        setCurrentGameId(advisorId);
                        setSecondsPlayed(0);
                         setIsIdlePaused(false); // Removed isIdlePaused

                        const intervalId = setInterval(() => {
                             if (!isIdlePaused) { // Removed isIdlePaused condition
                                setSecondsPlayed(prevSeconds => {
                                    const nextSeconds = prevSeconds + 5;
                                    sendPlayTimeUpdate(advisorId, nextSeconds);
                                    return nextSeconds;
                                });
                            } // Removed isIdlePaused condition
                        }, 5000);
                        setPlayTimerIntervalId(intervalId);
                        const intervalStartTime = new Date();
                        console.log(`[${intervalStartTime.toLocaleTimeString()}] Play time counting started for embed/html game.`); // Log right after interval is set
                    } else {
                        console.error("Advisor ID not found in response.");
                    }
                } else {
                    console.error("Failed to start play action:", response.status);
                }
            } catch (error) {
                console.error("Error starting play action:", error);
            }


        }
        if (currentItem?.type === 'video') {
            const subscriptionStatus = await fetchSubscriptionStatus();
            // Check subscriptionStatus and make sure it's not null before accessing properties
            if (subscriptionStatus && subscriptionStatus.organizationList && subscriptionStatus.organizationList.length > 0) {
                // User has some kind of organization, check for specific subscription level if needed.
                // For now, assuming any organization means they are not 'community'
            } else {
                setIsProModalOpen(true); // Open Pro modal if no organization is found
                return; // Early return to prevent further execution for video type if not subscribed
            }
            showNotification(`Early access requested for "${currentItem.title}"!`);
            if (currentItem && currentItem._id) trackAction('request_early_access', currentItem._id);

        }
        if (currentItem?.type === 'dos') {
            // For DOS games, force a remount of the DosPlayer component
            setDosKey(Date.now());
            setIsPlaying(true);
            if (currentItem && currentItem._id) trackAction('play', currentItem._id);

            // Start play time tracking
            const payload = { action: 'play', sku_id: currentItem._id, user_id: userId || 'incognito', ip: userIp };
            console.log("Sending POST to ADVISOR_API_URL:", ADVISOR_API_URL, payload);
            try {
                const response = await fetch(ADVISOR_API_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log("POST to ADVISOR_API_URL response:", data);
                    if (data.advisor && data.advisor._id) {
                        const advisorId = data.advisor._id;
                        setCurrentGameId(advisorId);
                        setSecondsPlayed(0);
                         setIsIdlePaused(false); // Removed isIdlePaused

                        const intervalId = setInterval(() => {
                             if (!isIdlePaused) { // Removed isIdlePaused condition
                                setSecondsPlayed(prevSeconds => {
                                    const nextSeconds = prevSeconds + 5;
                                    sendPlayTimeUpdate(advisorId, nextSeconds);
                                    return nextSeconds;
                                });
                            } // Removed isIdlePaused condition
                        }, 5000);
                        setPlayTimerIntervalId(intervalId);
                        const intervalStartTime = new Date();
                        console.log(`[${intervalStartTime.toLocaleTimeString()}] Play time counting started for dos game.`); // Log right after interval is set
                    } else {
                        console.error("Advisor ID not found in response.");
                    }
                } else {
                    console.error("Failed to start play action:", response.status);
                }
            } catch (error) {
                console.error("Error starting play action:", error);
            }
        }
        setLastActivityTime(Date.now()); // Update last activity time on play
    };

    const handleLeave = () => {
        const currentItem = feedItems[currentItemIndex];
        setIsPlaying(false);
        if (currentItem && currentItem._id) trackAction('leave', currentItem._id);
        clearInterval(playTimerIntervalId);
        setPlayTimerIntervalId(null);
        setSecondsPlayed(0);
        setCurrentGameId(null);
    };


    // Share Action
    const handleShare = async () => {
        const currentItem = feedItems[currentItemIndex];
        const currentUrl = `${window.location.origin}/${currentItem?._id}`;
        try {
            await navigator.clipboard.writeText(currentUrl);
            showNotification("Link copied to clipboard!");
            if (currentItem && currentItem._id) trackAction('share', currentItem._id);
        } catch (err) {
            console.error('Failed to copy URL: ', err);
            showNotification("Failed to copy link.");
        }
    };

    const handleReportSubmit = () => {
        if (reportReason && currentItem) {
            reportAction(currentItem._id, reportReason);
        }
    };

    const handleFavoriteClick = () => {
        if (isSignedIn && currentItem) {
            favoriteAction(currentItem._id, favoriteStates[currentItem._id]);
        }
    };

    // Close dropdown on click outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowUserDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const closeUserDropdown = () => setShowUserDropdown(false);
    const toggleUserDropdown = () => setShowUserDropdown(prev => !prev);
    const handleProfileClick = () => {
        setIsUserProfileModalOpen(true);
        closeUserDropdown();
    };
    const closeUserProfileModal = () => setIsUserProfileModalOpen(false);
    const closeAuthModal = () => {
        setIsAuthModalOpen(false);
        setIsPlaying(false);
        if (feedItems[currentItemIndex]?.type === 'dos' && dosbox.current) {
            try {
                if (typeof dosbox.current.exit === 'function') {
                    dosbox.current.exit();
                } else if (typeof dosbox.current.stop === 'function') {
                    dosbox.current.stop();
                }
                dosbox.current = null;
            } catch (err) {
                console.error("Error exiting DOS:", err);
            }
        }
    };

    const openGameUploadModal = () => setIsGameUploadModalOpen(true); // Function to open GameUploadModal
    const closeGameUploadModal = () => setIsGameUploadModalOpen(false); // Function to close GameUploadModal
    const openReportModal = () => setIsReportModalOpen(true);
    const handleReportReasonChange = (e) => setReportReason(e.target.value);
    const closeVideoModal = () => setIsVideoButtonModalOpen(false);
    const openVideoModal = () => setIsVideoButtonModalOpen(true);
    const closeProModal = () => setIsProModalOpen(false);

    const toggleSearch = () => {
        setIsSearchActive(!isSearchActive);
        setIsSearchVisible(!isSearchActive); // Open search results when search is toggled on
        // Focus on input when opening
        if (!isSearchActive && searchInputRef.current) {
            setTimeout(() => searchInputRef.current.focus(), 100); // Delay to allow render
        }
        if (isSearchActive) { // Close search results when search is toggled off
            closeSearch();
        }
    };

    const openGameModal = () => {
        setIsGameModalOpen(true);
    };

    const closeGameModal = () => {
        setIsGameModalOpen(false);
    };


    // Rendering
    const currentItem = feedItems.length > 0 ? feedItems[currentItemIndex] : null;
    const isFavorite = currentItem ? favoriteStates[currentItem._id] : false;


    if (isLoading && !isPaginating) {
        return (
            <div className="feed-container loading-indicator">
                <div className="orange-spinner"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="feed-container error-container">
                <div className="error-card">
                    <div className="error-title">{error === 'System Overload' ? 'System Overload' : 'Error'}</div>
                    <div className="error-description">
                        {error === 'System Overload'
                            ? "We're dealing with increasing demand. Please come back in a few minutes."
                            : error}
                    </div>
                    <button onClick={() => window.location.reload()} className="error-retry-button">Reload</button>
                </div>
            </div>
        );
    }

    if (!currentItem) {
        return (
            <div className="feed-container">
                {isLoading ? <div className="orange-spinner"></div> : "No feed items available."}
            </div>
        );
    }

    const isEmbed = currentItem.type === 'embed';
    const isVideo = currentItem.type === 'video';
    const isDos = currentItem.type === 'dos';
    const isEmbedPlayr = currentItem.type === 'embed_playr';
    const isHtml = currentItem.type === 'html';
    let thumbnailUrl = window.innerWidth <= 768 && currentItem.mobile_thumbnail !== 'none'
        ? currentItem.mobile_thumbnail
        : currentItem.desktop_thumbnail;

    if (isVideo && thumbnailUrl === 'none') {
        thumbnailUrl = DEFAULT_VIDEO_THUMBNAIL;
    }

    const showPlaceholder = thumbnailUrl === 'none' || (!isEmbed && !isVideo && !isDos && !isEmbedPlayr && !isHtml);

    const contentKey = `${currentItem._id}-${isPlaying}`;
    const animationClass = slideDirection === 'up'
        ? 'animate-slide-up'
        : (slideDirection === 'down' ? 'animate-slide-down' : '');

    return (
        <>
            <div
                {...swipeHandlers}
                className={`feed-container ${isPlaying ? 'is-playing' : ''}`}
                data-single-item={feedItems.length <= 1}
                onKeyDown={(e) => { if (e.key === 'ArrowDown') handleDownArrow(); }} // Listen for ArrowDown key press
                tabIndex={0} // Make the container focusable for key events
            >
                <Link to="/" target="_blank"> {/* Updated Link here */}
                    <img src={logo} alt="Logo" className="feed-logo" />
                </Link>
                <div className="top-right-icons">
                    {isSignedIn && (
                        <>
                            <Link to="/studio" title="Studio"><FontAwesomeIcon icon={faWandSparkles} /></Link>
                            <Link to="/Analytics" title="Analytics"><FontAwesomeIcon icon={faDollarSign} /></Link>

                        </>
                    )}
                    {isSignedIn && ( // Conditionally render search icon based on isSignedIn
                        <button className="search-icon-button" onClick={toggleSearch} title="Search">
                            <FontAwesomeIcon icon={faSearch} className={isSearchActive ? 'search-icon active' : 'search-icon'} />
                        </button>
                    )}
                     {isSignedIn && ( // Conditionally render plus icon based on isSignedIn
                        <button className="upload-icon-button" onClick={openGameUploadModal} title="Upload Game">
                            <FontAwesomeIcon icon={faPlus} className="upload-icon" />
                        </button>
                    )}
                    <div className="profile-container-header" ref={dropdownRef}>
                        {!isSignedIn ? (
                            <SignInButton mode="modal" asChild afterSignInUrl="/">
                                <button className="signin-button-custom">Login</button>
                            </SignInButton>
                        ) : (
                            <>
                                <button className="profile-button-header" onClick={toggleUserDropdown}>
                                    {user?.imageUrl ? (
                                        <img src={user.imageUrl} alt="Profile" className="desktop-profile-pic" />
                                    ) : user?.firstName ? (
                                        <span className="profile-initials-header">{user.firstName.charAt(0).toUpperCase()}</span>
                                    ) : (
                                        <FontAwesomeIcon icon={faUserCircle} style={{ color: '#90D6FF', fontSize: '1.7em' }} />
                                    )}
                                </button>
                                {showUserDropdown && (
                                    <div className="user-dropdown-header">
                                        <div className="dropdown-item" onClick={handleProfileClick}>Profile</div>
                                        <div className="dropdown-item signout-dropdown-item"><SignOutButton>Sign Out</SignOutButton></div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {isSearchActive && (
                    <div className="search-bar-container">
                        <input
                            type="text"
                            placeholder="Search games..."
                            className="search-input"
                            ref={searchInputRef}
                            onChange={handleSearchChange}
                            onSubmit={handleSearchSubmit}
                        />
                        <button className="search-close-button" onClick={closeSearch}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                )}

                {isSearchVisible && searchResults.length > 0 && (
                    <div className="search-results-overlay" ref={searchResultsRef}>
                        <button className="search-results-close-button" onClick={closeSearch}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <div className="search-results-grid">
                            {searchResults.map(item => (
                                <div key={item._id} className="search-result-card" onClick={() => handleSearchResultClick(item._id)}>
                                    <img
                                        src={item.desktop_thumbnail}
                                        alt={item.title}
                                        className="search-result-thumbnail"
                                        onError={(e) => { e.target.onerror = null; e.target.src=DEFAULT_VIDEO_THUMBNAIL}}
                                    />
                                    <div className="search-result-title">{item.title}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}


                {isPaginating && <div className="loading-overlay">Loading more...</div>}
                <button onClick={handleLeave} className="feed-leave-button">Leave</button>

                <div key={contentKey} className={`feed-content-wrapper ${animationClass}`}>
                    {(isEmbed || isEmbedPlayr || isVideo || isHtml) && !isPlaying && (
                        showPlaceholder ? (
                            <div className="feed-placeholder">Preview N/A</div>
                        ) : (
                            <img
                                src={thumbnailUrl}
                                alt={`${currentItem.title} thumbnail`}
                                className="feed-thumbnail"
                                onError={(e) => { e.target.style.display = 'none'; }}
                                style={{ objectFit: 'cover' }} // Updated objectFit here
                            />
                        )
                    )}
                    {isEmbed && isPlaying && (
                        <iframe
                            src={currentItem.name}
                            title={currentItem.title}
                            className="feed-iframe"
                            allow="accelerometer; ambient-light-sensor; camera; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    )}
                    {isEmbedPlayr && isPlaying && (
                        <div className="feed-iframe-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                            <iframe
                                src={currentItem.name}
                                title={currentItem.title}
                                className="feed-iframe feed-iframe-embed-playr"
                                style={{ width: '100%', height: 'calc(100% + 10%)', marginTop: '-10%' }}
                                allow="accelerometer; ambient-light-sensor; camera; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}

                    {isVideo && isPlaying && (
                        <video
                            key={currentItem._id}
                            src={currentItem.name}
                            className="feed-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls={false}
                            style={{objectFit: 'contain'}}
                        ></video>
                    )}
                    {isDos && !isPlaying && (
                        showPlaceholder ? (
                            <div className="feed-placeholder">Preview N/A</div>
                        ) : (
                            <img
                                src={thumbnailUrl}
                                alt={`${currentItem.title} thumbnail`}
                                className="feed-thumbnail"
                                onError={(e) => { e.target.style.display = 'none'; }}
                                style={{ objectFit: 'cover' }} // Updated objectFit here
                            />
                        )
                    )}
                    {isDos && isPlaying && currentItem && currentItem.name && (
                        <div className="dos-game-container" style={{ width: '100%', height: '100%' }}>
                            <DosPlayer
                                key={dosKey} // Use the key to force remount
                                bundleUrl={currentItem.name}
                                onDosInstanceCreated={handleDosInstanceCreated}
                                onDosInstanceExited={handleDosInstanceExited}
                            />
                        </div>
                    )}
                    {isHtml && isPlaying && currentItem && currentItem.name && (
                        <iframe
                            src={currentItem.name}
                            title={currentItem.title}
                            className="feed-iframe"
                            allowFullScreen
                        ></iframe>
                    )}
                    {!isEmbed && !isVideo && !isDos && !isEmbedPlayr && !isHtml && showPlaceholder && (
                        <div className="feed-placeholder">Content N/A</div>
                    )}
                </div>

                <div className="feed-info">
                    <div className="feed-title">{currentItem.title}</div>
                    <div className="feed-description">{currentItem.description}</div>
                    <div className="feed-username">
                        by{' '}
                        {currentItem.username_link && currentItem.username_link !== 'none' ? (
                            <a href={currentItem.username_link} target="_blank" rel="noopener noreferrer">
                                {currentItem.username}
                            </a>
                        ) : (
                            currentItem.username
                        )}
                    </div>
                    {isVideo && !isPlaying && (
                        <div className="feed-action-buttons"> {/* Centered buttons container */}
                            <button onClick={handlePlayOrRequest} className="action-text-request">
                                Request Early Access
                            </button>
                            <button onClick={openVideoModal} className="watch-button" > {/* Adjusted top here */}
                                Watch Trailer
                            </button>
                        </div>
                    )}
                    {!isPlaying && (
                        <>
                            {(isEmbed || isDos || isEmbedPlayr || isHtml) && (
                                <button onClick={handlePlayOrRequest} className="action-icon-play" title="Play">
                                    <FontAwesomeIcon icon={faPlay} />
                                </button>
                            )}
                        </>
                    )}
                </div>

                {currentItemIndex > 0 && (
                    <div className="feed-nav-arrow arrow-up" onClick={handlePrevious} title="Previous">▲</div>
                )}
                {(currentItemIndex < feedItems.length - 1 || currentPage < totalPages) && (
                    <div className="feed-nav-arrow arrow-down" onClick={handleNext} title="Next">▼</div>
                )}

                <div className="feed-action-icons">
                    <FontAwesomeIcon
                        icon={faShareAlt}
                        className="feed-action-icon share-icon"
                        onClick={handleShare}
                        title="Share"
                    />
                    {isSignedIn && (
                        <FontAwesomeIcon
                            icon={isFavorite ? faStarOutline : faStarOutline}
                            className={`feed-action-icon favorite-icon ${isFavorite ? 'favorited' : ''}`}
                            onClick={handleFavoriteClick}
                            title={isFavorite ? "Unfavorite" : "Favorite"}
                        />
                    )}
                    {isSignedIn && (
                        <FontAwesomeIcon
                            icon={faFlag}
                            className="feed-action-icon report-icon"
                            onClick={openReportModal}
                            title="Report"
                        />
                    )}
                </div>
                {isSignedIn && (
                    <button className="game-icon-button" onClick={openGameModal} title="Games">
                        <FontAwesomeIcon icon={faGamepad} className="game-icon" />
                    </button>
                )}


                <div className={`copy-notification ${notification.visible ? 'visible' : ''}`}>
                    {notification.message}
                </div>
            </div>
            {isUserProfileModalOpen && <UserProfileModal onClose={closeUserProfileModal} />}
            {isAuthModalOpen && <AuthModal onClose={closeAuthModal} />}
            {isReportModalOpen && currentItem && (
                <ReportModal
                    onClose={closeReportModal}
                    title={currentItem.title}
                    reportReason={reportReason}
                    onReportReasonChange={handleReportReasonChange}
                    onSubmitReport={handleReportSubmit}
                />
            )}
            {isVideoButtonModalOpen && currentItem && (
                <VideoModal onClose={closeVideoModal} videoUrl={currentItem.name} title={currentItem.title} />
            )}
            {isProModalOpen && <ProModal onClose={closeProModal} />}
            {isGameUploadModalOpen && <GameUploadModal onClose={closeGameUploadModal} />} {/* Render GameUploadModal */}
            {isGameModalOpen && <GameModal onClose={closeGameModal} navigate={navigate} userId={userId} />}
        </>
    );
}

// Game Modal Component
const GameModal = ({ onClose, navigate, userId }) => { // Receive userId as prop
    const [gameItems, setGameItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGames = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`https://api.neocu.com/api/feed?category=sku&user_id=${userId || ''}`); // Use userId here
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.isSuccess && data.feedList) {
                    setGameItems(data.feedList);
                } else {
                    setError('Failed to load games');
                }
            } catch (err) {
                setError('Failed to load games');
                console.error("Error fetching games:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchGames();
    }, [userId]); // userId dependency

    const handleGameCardClick = (itemId) => {
        navigate(`/${itemId}`);
        onClose(); // Close modal after navigation
    };

    return (
        <div className="game-modal-overlay">
            <div className="game-modal-content">
                <button className="game-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="game-modal-title">Games</h2>
                {loading && <div className="orange-spinner"></div>}
                {error && <div className="error-message">Failed to load games.</div>}
                {!loading && !error && (
                    <div className="game-results-grid">
                        {gameItems.map(item => (
                            <div key={item._id} className="game-result-card" onClick={() => handleGameCardClick(item._id)}>
                                <img
                                    src={item.desktop_thumbnail || DEFAULT_GAME_ICON}
                                    alt={item.title}
                                    className="game-result-thumbnail"
                                    onError={(e) => { e.target.onerror = null; e.target.src=DEFAULT_GAME_ICON}}
                                />
                                <div className="game-result-title">{item.title}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};


// User Profile Modal Component
const UserProfileModal = ({ onClose }) => {
    return (
        <div className="compute-modal-overlay" onClick={onClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            <div className="compute-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <div style={{ padding: '20px', width: '100%' }}>
                    <UserProfile path="/user-profile" routing="path" />
                </div>
            </div>
        </div>
    );
};

// Auth Modal Component
const AuthModal = ({ onClose }) => {
    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-content">
                <button className="auth-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="auth-modal-title">Neocu Community</h2>
                <ul className="auth-modal-checklist">
                    <li>Discover exclusive game content.</li>
                    <li>Get early access to new experiences.</li>
                    <li>Connect with a community of gamers.</li>
                    <li>Personalize your feed with your interests.</li>
                    <li>Support creators and the gaming ecosystem.</li>
                </ul>
                <div className="auth-modal-buttons">
                    <SignInButton mode="modal" asChild afterSignInUrl="/">
                        <button className="auth-modal-button auth-modal-login-button">Login</button>
                    </SignInButton>
                    <SignUpButton mode="modal" asChild afterSignUpUrl="/">
                        <button className="auth-modal-button auth-modal-signup-button">Sign Up</button>
                    </SignUpButton>
                </div>
            </div>
        </div>
    );
};

// Report Modal Component
const ReportModal = ({ onClose, title, reportReason, onReportReasonChange, onSubmitReport }) => {
    return (
        <div className="report-modal-overlay">
            <div className="report-modal-content">
                <button className="report-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="report-modal-title">Report {title}</h2>
                <div className="report-options">
                    <label className="report-option">
                        <input type="radio" name="reportReason" value="Spam" checked={reportReason === 'Spam'} onChange={onReportReasonChange} /> Spam
                    </label>
                    <label className="report-option">
                        <input type="radio" name="reportReason" value="Broken" checked={reportReason === 'Broken'} onChange={onReportReasonChange} /> Broken
                    </label>
                    <label className="report-option">
                        <input type="radio" name="reportReason" value="Misleading" checked={reportReason === 'Misleading'} onChange={onReportReasonChange} /> Misleading
                    </label>
                    <label className="report-option">
                        <input type="radio" name="reportReason" value="Other" checked={reportReason === 'Other'} onChange={onReportReasonChange} /> Other
                    </label>
                </div>
                <div className="report-modal-actions">
                    <button className="report-modal-submit-button" onClick={onSubmitReport} disabled={!reportReason}>Submit</button>
                </div>
            </div>
        </div>
    );
};


// Video Modal Component
const VideoModal = ({ onClose, videoUrl, title }) => {
    return (
        <div className="video-modal-overlay">
            <div className="video-modal-content">
                <button className="video-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="video-modal-title"> {title}</h2>
                <div className="video-container">
                    <video
                        src={videoUrl}
                        className="modal-feed-video"
                        autoPlay
                        controls
                        playsInline
                        style={{objectFit: 'contain'}} // Ensure video fits within modal
                    ></video>
                </div>
            </div>
        </div>
    );
};

// Pro Modal Component
const ProModal = ({ onClose }) => {
    return (
        <div className="pro-modal-overlay">
            <div className="pro-modal-content">
                <button className="pro-modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="pro-modal-title">Neocu Pro</h2>
                <div className="pro-modal-container"> {/* Flex container for layout */}
                    <div className="pro-modal-plans-area"> {/* Area for plans */}
                        <div className="pro-modal-plans">
                            <a href="https://buy.stripe.com/eVaaFNcuC8Wj4TK4ha" target="_blank" rel="noopener noreferrer" className="pro-plan-card">
                                <h3>1 Month</h3>
                                <p className="pro-plan-description">Unlock pro features for a month.</p>
                                <span className="pro-plan-price">$12</span>
                            </a>
                            <a href="https://buy.stripe.com/cN28xF9iqa0n9a07tn" target="_blank" rel="noopener noreferrer" className="pro-plan-card">
                                <h3>3 Months</h3>
                                <p className="pro-plan-description">Save with a quarterly subscription.</p>
                                <span className="pro-plan-price">$30</span>
                            </a>
                            <a href="https://buy.stripe.com/cN2eW3dyGdcz0DuaFA" target="_blank" rel="noopener noreferrer" className="pro-plan-card">
                                <h3>12 Months</h3>
                                <p className="pro-plan-description">Best value. 5 months free.</p>
                                <span className="pro-plan-price">$84</span>
                            </a>
                        </div>
                    </div>
                    <div className="pro-modal-features-area"> {/* Area for features */}
    <div className="pro-modal-features">
        <div>🎮 <strong>Unlimited Games</strong> – Play without limits, forever.</div>
        <div>🚀 <strong>Day One Access Passes</strong> – Be ready the second the game drops.</div>
        <div>🛠️ <strong>AI Game Editor</strong> – Build your own games with AI-powered tools.</div>
        <div>💸 <strong>Monetize Your Games</strong> – Make real money from your creations.</div>
        <div>🛡️ <strong>Unlimited Access Passes</strong> – No daily/weekly restrictions.</div>
        <div>🎁 <strong>Earn Rewards Just by Playing</strong> – Unlock exclusive in-game items.</div>
        <div>👑 <strong>Supporter Badge</strong> – Special recognition in-game (or on profile).</div>
        <div>🎵 <strong>Bonus Content</strong> – Concept art, music, wallpapers.</div>
    </div>
</div>

                </div>
            </div>
        </div>
    );
};


export default Feed;