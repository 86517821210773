import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css'; // Global App CSS
// Remove Header import if you don't want it on the feed page
// import Header from './components/Header';
import Home from './components/Home'; // Keep if needed for other routes
import Pricing from './components/Pricing';
import Features from './components/Features';
import Enterprise from './components/Enterprise';
import Careers from './components/Careers';
import Blogs from './blogs/Blogs';
import Studio from './components/Studio'; 
import Analytics from './components/Analytics'; // 

// --- Import the new Feed component ---
import Feed from './components/Feed'; // Adjust path if needed

// --- Lazy load components ---
// Blog components
const BlenderShortcuts = lazy(() => import('./blogs/BlenderShortcuts'));
const BlenderGreasePencil = lazy(() => import('./blogs/BlenderGreasePencil'));
const BlenderExtrude = lazy(() => import('./blogs/BlenderExtrude'));
const BlenderUVMapping = lazy(() => import('./blogs/BlenderUVMapping'));
const BlenderLighting = lazy(() => import('./blogs/BlenderLighting'));

// Game component (still lazy loaded)
const Game = lazy(() => import('./components/Game'));

// --- Loading Fallback Component ---
const LoadingFallback = ({ message = "Loading..." }) => (
  <div style={{ textAlign: 'center', padding: '50px', fontSize: '1.2em', color: '#ff7f00', minHeight: 'calc(100vh - 0px)', /* Adjust height if header removed */ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black' }}>
    {message}
  </div>
);


function App() {
  return (
    <Router>
      {/* <Header /> */} {/* Conditionally render Header or remove if not needed globally */}
      {/* The main container div might not be needed if Feed handles 100% height */}
      {/* <div className="App"> */}
        <Routes>
          {/* --- NEW: Feed Route as the main entry --- */}

          <Route path="/" element={<Feed />} />
          <Route path="/studio" element={<Studio />} />
          <Route path="/studio/:id" element={<Studio />} />
          <Route path="/analytics" element={<Analytics />} />

          <Route path="/:id" element={<Feed />} /> {/* Route for specific feed items */}

          {/* Standard Pages (Keep if needed, but ensure navigation exists) */}
          {/* You might want to add a Header/Navbar *inside* these specific routes */}
          {/* if you removed the global one */}
          <Route path="/home-example" element={<Home />} /> {/* Example: Keep old home accessible */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/enterprise" element={<Enterprise />} />
          <Route path="/careers" element={<Careers />} />
          

          {/* Blog Listing */}
          <Route path="/blogs" element={<Blogs />} />

          {/* Individual Blog Posts (Lazy Loaded) */}
          <Route
            path="/blogs/blender-shortcuts"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderShortcuts />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-grease-pencil"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderGreasePencil />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-extrude"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderExtrude />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-uv-mapping"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderUVMapping />
              </Suspense>
            }
          />
           <Route
            path="/blogs/blender-realistic-lighting"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderLighting />
              </Suspense>
            }
          />

          {/* Game Route (Lazy Loaded) */}
          <Route
            path="/game/:id" // Use :id for the parameter
            element={
              <Suspense fallback={<LoadingFallback message="Loading Game..." />}>
                <Game />
              </Suspense>
            }
          />

          {/* Optional: Add a 404 Not Found Route */}
          {/* <Route path="*" element={<NotFound />} /> */}

        </Routes>
      {/* </div> */}
    </Router>
  );
}

export default App;